@import '_base';

body {
  @include resize('font-size', 16px);
}
.inner {
  @include resize('width', 1280px);
}
.full_banner {
  //height: 766px;
  background-color: #f1efec;

  .inner {
    @include resize('min-width', 1280px);
    max-width: 1440px;
    width: 100%;
    overflow: hidden;
  }
  .jw_player {
    @include resize('width', 1920px);
    @include resize('height', 788px);
    overflow: hidden;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

    .jwplayer {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: transparent;

      .jw-aspect {
        padding-top: 41% !important;
      }
    }
  }
  .contents {
    ul {
      font-size: 0;
      white-space: nowrap;
      position: relative;
      left: 0;
      -webkit-transition: left 1.2s;
      -moz-transition: left 1.2s;
      -ms-transition: left 1.2s;
      -o-transition: left 1.2s;
      transition: left 1.2s;
    }

    li {
      width: 100%;
      position: relative;
      overflow: hidden;
      @include resize('height', 788px);
      //display: none;
      //&.show {
      //  display: block;
      //}
      display: inline-block;
    }
    .bg_area {
      height: 100%;
      //video {
      //  width: 100%;
      //  height: auto;
      //  display: block;
      //  position: relative;
      //  top: 50%;
      //  left: 50%;
      //  //-webkit-transform: translateX(-50%);
      //  //-moz-transform: translateX(-50%);
      //  //-ms-transform: translateX(-50%);
      //  //-o-transform: translateX(-50%);
      //  //transform: translateX(-50%);
      //  -webkit-transform: translate(-50%,-50%);
      //  -moz-transform: translate(-50%,-50%);
      //  -ms-transform: translate(-50%,-50%);
      //  -o-transform: translate(-50%,-50%);
      //  transform: translate(-50%,-50%);
      //}
      img {
        display: block;
        position: relative;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
    .float_area {
      width: 100%;
      height: 100%;
      @include resize-multiple(
        'padding',
        0,
        ($column + $gutter),
        0,
        ($column + $gutter)
      );
      position: absolute;
      top: 0;
      left: 0;
      .inner_area {
        max-width: 1024px;
        margin: 0 auto;
      }
    }
    .left_section {
      @include resize('padding-top', 318px);
      h4 {
        font-size: 35px;
        img {
          @include resize('width', 344px);
          height: auto;
          display: block;
        }
      }
      p {
        @include resize('margin-top', 32px);
        @include resize('font-size', 24px);
      }
      button,
      a {
        width: 232px;
        margin-top: 28px;
        position: relative;
        z-index: 10;
        cursor: pointer;
      }
    }
    .right_section {
      @include resize('padding-top', 287px);
      text-align: right;
      .img_area {
        //@include resize('width', 390px);
        //@include resize('height', 277px);
        width: 280px;
        height: 198px;
        display: inline-block;
        border: 5px solid #fff;
        //border: 7px solid #fff;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .txt_area {
        margin-top: 13px;
        //@include resize('margin-top', 56px);
        font-size: 14px;
        //font-size: 16px;
        text-align: right;
      }
    }
  }
  .pagination_group {
    width: 1024px;
    position: absolute;
    bottom: 48px;
    left: 50%;
    font-size: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;

    .pagination {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    button {
      width: 7px;
      height: 7px;
      margin: 4px;
      padding: 0;
      background-color: #000;
      border: none;

      &.on {
        background-color: $highlight-color;
      }
    }
  }
  .controller {
    button {
      width: 35%;
      height: 100%;
      padding: 0 14px;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .btn_prev {
      left: 0;
    }
    .btn_next {
      right: 0;
    }
  }
}

.full_banner,
.real_video,
.real_teacher {
  .controller {
    .btn_prev {
      &:hover {
        cursor: url('https://cf.realclass.co.kr/qualson/images/home_cursor_left_lg.png'),
          pointer;
      }
    }
    .btn_next {
      &:hover {
        cursor: url('https://cf.realclass.co.kr/qualson/images/home_cursor_right_lg.png'),
          pointer;
      }
    }
  }
}
.main {
  min-width: $base-width;
  width: 100%;
  overflow: hidden;
  h2,
  h3 {
    font-weight: 400;
  }
  h2 {
    @include resize('font-size', 30px);

    &.title_sm {
      @include resize('font-size', 26px);
    }
  }

  .left_section {
    float: left;
  }
  .right_section {
    float: right;
  }
}
.real_customers {
  padding: 62px 0 21px;
  border-bottom: 2px solid #5cd99d;
  text-align: center;

  .inner {
    width: 1440px;
  }
  &.open {
    .img_more {
      display: block;
    }
    h3 {
      margin-bottom: 30px;
    }
  }
  h3 {
    margin-bottom: 10px;
    color: #767e81;
    font-size: 16px;
    line-height: 1.3;
  }
  p {
    margin-bottom: 33px;
    color: #a3acaf;
    font-size: 14px;
    line-height: 1.4;
  }
  .btn_more {
    width: 100%;
    margin-top: 5px;
    padding: 14px 0;
    font-size: 12px;
    color: $highlight-color;
    border: none;
    background-color: transparent;
    &:after {
      content: '';
      margin-left: 4px;
      display: inline-block;
      vertical-align: middle;
      @include retina-sprite($common-btn-more-green-group);
    }
  }
  .img_more {
    display: none;
  }
  .slide_area {
    overflow: hidden;
  }
  .img_group {
    position: relative;
    top: 0;
    left: 0;

    -webkit-animation: slide 125s linear infinite;
    -moz-animation: slide 125s linear infinite;
    animation: slide 125s linear infinite;
  }
  .img_group,
  .img_more {
    width: 100%;
    white-space: nowrap;

    img {
      width: auto;
      height: 116px;
      margin: 0 auto;
      display: inline-block;
      vertical-align: top;
    }
  }
}

@-webkit-keyframes slide {
  0% {
    left: 0;
  }

  100% {
    left: -8409px;
  }
}
@-moz-keyframes slide {
  0% {
    left: 0;
  }

  100% {
    left: -8409px;
  }
}
@keyframes slide {
  0% {
    left: 0;
  }

  100% {
    left: -8409px;
  }
}
.real_english,
.real_easy {
  h3 {
    color: $highlight-color;
  }

  li {
    width: $column * 2 + $gutter * 2;
    //@include resize('width', ($column * 2 + $gutter * 3));
    position: relative;
    display: inline-block;

    span {
      margin: 0 auto;
      position: relative;
      display: block;

      img {
        position: absolute;
      }
    }
    p {
      color: #9f9f9f;
      line-height: 1.4;
    }
  }
}
.real_english,
.real_easy,
.real_question {
  text-align: center;
  h2 {
    color: #fff;
    line-height: 1.4;

    strong {
      color: $highlight-color;
      font-weight: 400;
    }
  }
}
.real_english {
  @include resize-multiple('padding', 106px, 0, 130px, 0);

  h2 {
    color: #000;
    strong {
      margin-top: 10px;
      display: block;
      font-weight: 600;
      font-size: 23px;
    }
  }
  ul {
    @include resize('margin-top', 66px);
  }
  li {
    padding: 0 14px;

    & + li {
      &:after {
        content: '';
        position: absolute;
        top: 64px;
        left: -14px;
        @include retina-sprite($common-icon-plus-group);
      }
    }
    span {
      width: 151px;
      height: 144px;
      //@include resize('width', 151px);
      //@include resize('height', 144px);
      border: 7px solid #bcc2c9;

      i {
        //padding: 10px 0;
        position: absolute;
        top: 40px;
        left: -9px;
        background-color: #fff;
        font-size: 0;

        &:before {
          content: '';
          width: 140px;
          height: 64px;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: auto 64px;
        }
      }
    }
    p {
      color: #626569;
      font-size: 15px;
      margin-top: 24px;
    }
  }
  .plus_video {
    i:before {
      background-image: url('https://cf.realclass.co.kr/qualson/images/txt_main_real_video.png');
      //@include retina-sprite($common-txt-real-video-group);
    }
  }
  .plus_teacher {
    i:before {
      background-image: url('https://cf.realclass.co.kr/qualson/images/txt_main_real_teacher.png');
      //@include retina-sprite($common-txt-real-teacher-group);
    }
  }
  .plus_training {
    i:before {
      background-image: url('https://cf.realclass.co.kr/qualson/images/txt_main_real_training.png');
      //@include retina-sprite($common-txt-real-training-group);
    }
  }
}
.real_video {
  overflow: hidden;

  .top_section,
  .bottom_section {
    .inner {
      @include resize-multiple(
        'padding',
        0,
        ($column + $gutter),
        0,
        ($column + $gutter)
      );
    }
  }
  .top_section {
    @include resize('height', 581px);
    @include resize-multiple('padding', 120px, 0, 0, 0);
    position: relative;
    background-color: #3ad478;
  }
  .bottom_section {
    @include resize('height', 581px);
    background-color: #fff;
    .inner {
      height: 100%;
    }
  }
  .pagination,
  .controller button,
  .contract_info_group {
    z-index: 1;
  }
  .slides {
    width: 100%;
    @include resize('min-height', 597px);
    position: absolute;
    left: 0;
    @include resize('top', -270px);
    padding: 0 187px 0 220px;
    //@include resize-multiple('padding', 0, $column * 2 + $gutter * 2, 0, $column * 3 + $gutter * 3);

    li {
      display: none;

      &.show {
        display: block;
      }
    }
    .media_area,
    .review_area {
      width: 100%;
      //@include resize('width', $column * 6 + $gutter * 5);
    }
    .media_group {
      position: relative;
      @include resize('width', $column * 6 + $gutter * 5);
    }
    .media_area {
      position: relative;
      padding-top: 56%;
      border: 2px solid $highlight-color;
      z-index: 1;
      .img_area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #fff;

        img {
          margin-top: -3px;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: auto;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
      .jw_player {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      video {
        width: 100%;
        height: auto;
        display: block;
      }
      &.playing {
        a {
          background: transparent;
        }
      }
      a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        //background: transparent url('https://djtha9evtgm1n.cloudfront.net/images/bg_main_player.png') no-repeat center;
        //background-size: 100% auto;
      }
      p {
        @include resize('width', 242px);
        @include resize('height', 106px);
        @include resize('padding-top', 30px);
        position: absolute;
        top: -27px;
        left: -23px;
        background-color: transparentize(#48f28c, 0.1);
        text-align: center;
        box-shadow: 6px 5px 0 rgba(0, 0, 0, 0.15);

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          line-height: 1.3;
          color: #fff;
          font-size: 24px;
          white-space: nowrap;
        }
      }
    }
    .review_area {
      position: relative;
      @include resize-multiple('margin', -110px, 0, 0, $column + $gutter);
      @include resize-multiple('padding', 146px, 50px, 0, 50px);
      background-color: rgba(235, 235, 235, 0.9);
      border: 1px solid $highlight-color;
    }
    .bg_area {
      position: absolute;
      img {
        height: auto;
      }
    }
    .slide1 {
      .bg_area {
        @include resize('right', -116px);
        @include resize('bottom', -168px);
        img {
          @include resize('width', 529px);
        }
      }
    }
    .slide2 {
      .bg_area {
        @include resize('right', -247px);
        @include resize('bottom', -118px);
        img {
          @include resize('width', 652px);
        }
      }
    }
    .slide3 {
      .bg_area {
        @include resize('right', -128px);
        @include resize('bottom', -134px);
        img {
          @include resize('width', 1370px);
        }
      }
    }
  }
  .rel_content {
    position: relative;
  }
  .pagination {
    position: absolute;
    bottom: 140px;
    //@include resize('left', $column * 3 + $gutter * 3);
    left: 214px;

    button {
      display: block;
      border: none;
      background-color: transparent;
      color: #687980;
      font-weight: 600;

      &.on {
        color: $highlight-color;
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          margin-left: 6px;
          display: inline-block;
          border-radius: 50%;
          vertical-align: middle;
          background-color: $highlight-color;
        }
      }
      & + button {
        margin-top: 10px;
      }
    }
  }
  .controller {
    button {
      width: 410px;
      //width: 200px;
      height: 100%;
      //padding: 0 42px;
      position: absolute;
      top: 0;
    }
    .btn_prev {
      //left: 0;
      left: -210px;
    }
    .btn_next {
      //right: 0;
      right: -210px;
    }
  }
  .review_box {
    position: relative;
    &:before {
      left: -18px;
      top: -24px;
    }
    .txt_group {
      padding-bottom: 23px;
      line-height: 1.4;
      strong {
        margin-bottom: 12px;
        display: block;
      }
    }
    .img_group {
      padding: 13px 0 20px;
      border-top: 1px solid #2bde73;

      strong {
        font-size: 14px;
        font-weight: 400;
      }
      span + strong {
        margin-left: 20px;
      }
      strong + strong {
        &:before {
          content: '|';
          margin: 0 12px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  .slope {
    &:before {
      background-color: #fff;
    }
  }
  .contract_info_group {
    position: absolute;
    right: 0;
    bottom: 56px;

    p {
      padding: 0 32px;
      display: inline-block;
    }
    button {
      min-width: 126px;
      width: 126px;
      height: 43px;
      font-size: 14px;
      line-height: 39px;
      //color: #000;
      //background-color: rgba(255,255,255,.8);
    }

    .icon_turner {
      &:before {
        content: '';
        display: block;
        margin: 0 auto 15px;
        @include retina-sprite($common-turner-contents-group);
        //@include retina-sprite($common-icon-class-turner-group);
      }
    }
  }

  .section_title {
    display: inline-block;
    @include retina-sprite($common-real-video-group);
  }
  h2 {
    @include resize('margin-top', 28px);
  }
}
.real_teacher {
  @include resize-multiple('padding', 112px, 0, 0, 0);
  background-color: #ecf0f2;

  .inner {
    @include resize('padding-left', $column * 1 + $gutter * 1);
  }
  .section_title {
    display: inline-block;
    @include retina-sprite($common-real-teacher-group);
  }
  h2 {
    @include resize('margin-top', 25px);
  }
  .slides {
    @include resize('margin-top', 113px);
    @include resize('padding-right', $column * 1 + $gutter * 1);
    .contents {
      @include resize('min-height', 527px);
    }
  }
  .pagination_group {
    position: absolute;
    @include resize('top', -24px);
    @include resize('right', 20px);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);

    .scroll_area {
      @include resize('width', 632px);
      position: relative;
      overflow: hidden;
    }
    .bg_group {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0;
      white-space: nowrap;
      span {
        @include resize('width', 158px);
        @include resize('height', 218px);
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
      .slide1 {
        background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_1_off.png');
        &.on {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_1_on.png');
        }
      }
      .slide2 {
        background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_2_off.png');
        &.on {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_2_on.png');
        }
      }
      .slide3 {
        background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_3_off.png');
        &.on {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_3_on.png');
        }
      }
      .slide4 {
        background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_4_off.png');
        &.on {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_bg_4_on.png');
        }
      }
    }
  }
  .controller_pagination {
    .btn_prev,
    .btn_next {
      height: 120px;
      position: absolute;
      top: 0;
      border: none;
      background-color: transparent;
      display: none;

      &.on {
        display: block;
      }
      span {
        display: inline-block;
        @include retina-sprite($common-btn-prev-fill-gray-group);
      }
    }
    .btn_prev {
      left: -35px;
    }
    .btn_next {
      right: -20px;
      span {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
  .pagination {
    position: relative;
    font-size: 0;
    white-space: nowrap;

    button {
      padding: 0;
      position: relative;
      background-color: transparent;
      border: none;

      .img_area {
        @include resize('width', 158px);
        @include resize('height', 218px);
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% auto;

        img {
          display: block;
        }
      }
      .txt_area {
        @include resize('padding-top', 20px);
        position: absolute;
        top: 0;
        @include resize('font-size', 15px);
        color: #474747;
      }
      &.on {
        .txt_area {
          color: #000;
        }
      }
      &.slide_16 {
        .img_area {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_16_off.png');
        }
        &:hover,
        &:active,
        &.on {
          .img_area {
            background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_16_on.png');
          }
        }
        .txt_area {
          left: 0;
        }
      }
      &.slide_21 {
        .img_area {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_21_off.png');
        }
        &:hover,
        &:active,
        &.on {
          .img_area {
            background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_21_on.png');
          }
        }
        .txt_area {
          left: 22px;
        }
      }
      &.slide_18 {
        .img_area {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_18_off.png');
        }
        &:hover,
        &:active,
        &.on {
          .img_area {
            background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_18_on.png');
          }
        }
        .txt_area {
          left: 10px;
        }
      }
      &.slide_19 {
        //margin-left: -10px;
        .img_area {
          background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_19_off.png');
        }
        &:hover,
        &:active,
        &.on {
          .img_area {
            background-image: url('https://cf.realclass.co.kr/images/bg_main_teacher_19_on.png');
          }
        }
        .txt_area {
          left: 6px;
        }
      }
      &.slide_24 {
        //margin-left: -10px;
        .img_area {
          background-image: url('https://cf.realclass.co.kr/qualson/images/bg_main_teacher_24_off.png');
          background-positon: right top;
        }
        &:hover,
        &:active,
        &.on {
          .img_area {
            background-image: url('https://cf.realclass.co.kr/qualson/images/bg_main_teacher_24_on.png');
            background-positon: right top;
          }
        }
        .txt_area {
          left: 0;
        }
      }
      &.slide_27 {
        //margin-left: -10px;
        .img_area {
          background-image: url('https://cf.realclass.co.kr/qualson/images/bg_main_teacher_27_off.png');
          background-positon: right top;
        }
        &:hover,
        &:active,
        &.on {
          .img_area {
            background-image: url('https://cf.realclass.co.kr/qualson/images/bg_main_teacher_27_on.png');
            background-positon: right top;
          }
        }
        .txt_area {
          left: 0;
        }
      }
    }
  }
  .controller {
    button {
      //width: $column;
      width: 530px;
      height: 100%;
      top: 0;
    }
    .btn_prev {
      left: -320px;
      //left: ($gutter + $column) * -1;
    }
    .btn_next {
      //right: 0;
      right: -210px;
    }
  }
  .contents {
    li {
      @include resize('height', 527px);
      @include resize('min-height', 527px);
      position: relative;
      display: none;
      &.show {
        display: block;
      }
      .img_area {
        img {
          width: 842px;
          height: auto;
        }
      }
      .btn_link {
        z-index: 1;
      }
    }
  }
  .slide_16 {
    .teacher_info {
      .txt_area {
        @include resize('bottom', 176px);
      }
    }
  }
  .slide_21 {
    .teacher_info {
      .txt_area {
        @include resize('bottom', 146px);
      }
    }
  }
  .slide_18 {
    .teacher_info {
      .txt_area {
        @include resize('bottom', 146px);
      }
    }
  }
  .slide_19 {
    .teacher_info {
      .txt_area {
        @include resize('bottom', 146px);
      }
    }
  }
  .slide_24 {
    .teacher_info {
      .txt_area {
        @include resize('bottom', 176px);
      }
    }
  }
  .slide_27 {
    .teacher_info {
      .txt_area {
        @include resize('bottom', 170px);
      }
    }
  }
  .teacher_info {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;

    p {
      padding-right: 6px;

      span {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    strong {
      margin-bottom: 6px;
      display: inline-block;
      font-size: 20px;
      font-weight: 500;

      span {
        font-size: 14px;
      }
    }
    .txt_area {
      position: absolute;
      right: 0;
    }
    .img_area {
      img {
        display: block;
      }
    }
  }
  .btn_link {
    min-width: 272px;
    position: absolute;
    right: 0;
    //bottom: 55px;
    @include resize('bottom', 55px);
    text-align: center;
  }
  .icon_qualified {
    margin-top: 12px;
  }
  .review_box {
    @include resize('width', $column * 5 + $gutter * 5);
    @include resize('min-height', 243px);
    @include resize-multiple('padding', 40px, 35px, 20px, 50px);
    position: absolute;
    @include resize('bottom', -87px);
    border: 1px solid $highlight-color;
    background-color: rgba(235, 235, 235, 0.9);

    &:before {
      @include resize('top', 24px);
      @include resize('left', 34px);
    }
  }
  .txt_group {
    @include resize-multiple('padding', 0, 0, 22px, 3px);
    @include resize('margin-bottom', 15px);
    //@include resize('margin-right', -35px);
    @include resize('font-size', 18px);
    line-height: 1.4;
    border-bottom: 0.5px solid $highlight-color;

    strong {
      @include resize('margin-bottom', 12px);
      display: block;
    }
  }
  .img_group {
    //@include resize('margin-left', 9px);
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
    strong {
      @include resize('margin-left', 12px);
      @include resize('font-size', 14px);

      &:after {
        content: '|';
        @include resize('margin-left', 12px);
        display: inline-block;
      }
    }
    em {
      @include resize('margin-left', 12px);
      font-size: 12px;
      font-style: normal;
    }
  }
  .img_round {
    @include resize('width', 58px);
    @include resize('height', 58px);
    img {
      width: 100%;
      height: auto;
    }
  }
}

.real_training {
  @include resize-multiple('padding', 220px, 0, 0, 0);
  overflow: hidden;

  .top_section,
  .bottom_section {
    .inner {
      @include resize-multiple(
        'padding',
        0,
        $column + $gutter,
        0,
        $column + $gutter
      );
    }
  }
  .top_section {
    height: 742px;
  }
  .bottom_section {
    @include resize('min-height', 610px);
    @include resize('padding-bottom', 94px);
    background-color: #3ad478;

    .inner {
      padding-top: 180px;
    }
  }
  .slope {
    &:before {
      @include resize('height', 810px);
      background-color: #3ad478;
    }
  }
  .section_title {
    display: inline-block;
    @include retina-sprite($common-real-training-group);
  }

  .review_section {
    .txt_group {
      @include resize('min-height', 189px);
      strong {
        @include resize('margin-bottom', 8px);
        display: block;
      }
    }
    .img_group {
      em {
        margin-left: 0;
      }
    }
    .icon_qualified {
      @include resize('margin-left', 14px);
    }
  }
  h2 {
    @include resize('margin-top', 30px);
  }
  .step1,
  .step2 {
    span {
      display: inline-block;
    }
    .txt_area {
      @include resize('font-size', 26px);
    }
    .img_area {
      background: url('https://cf.realclass.co.kr/qualson/images/home_training02_shadow.png');
      background-size: 100% auto;

      img {
        @include resize('width', 270px);
        height: auto;
      }
    }
    strong {
      @include resize('margin-top', 24px);
      display: block;
      @include resize('font-size', 34px);
      font-weight: 500;
    }
  }
  .step1 {
    padding-right: 170px;
    text-align: right;
    color: $highlight-color;
    position: relative;
    z-index: 1;

    span {
      vertical-align: top;
    }
    .txt_area {
      padding: 58px 15px 0 0;
    }
  }
  .step2 {
    padding-left: 200px;
    position: absolute;
    top: -320px;
    left: 0;
    color: #fff;
    span {
      vertical-align: bottom;
    }
    .txt_area {
      padding: 0 0 46px 20px;
    }
  }
}

.real_easy {
  @include resize-multiple('padding', 104px, 0, 76px, 0);
  background-color: #3ad478;

  .inner {
    @include resize-multiple(
      'padding',
      0,
      $column + $gutter,
      0,
      $column + $gutter
    );
  }
  h2 {
    color: #0b6f38;
    strong {
      color: #fff;
    }
  }
  h3 {
    @include resize('margin-top', 90px);
    @include resize('font-size', 24px);
  }
  .btn_link {
    margin: 0 auto;
    @include resize('margin-top', 50px);
  }
  ol {
    @include resize('margin-top', 60px);
  }
  li {
    @include resize-multiple('padding', 0, 10px, 0, 10px);

    & + li {
      &:after {
        content: '';
        position: absolute;
        @include resize('top', 62px);
        @include resize('left', -10px);
        @include retina-sprite($common-icon-next-group);
      }
    }
    span {
      width: 152px;
      height: 145px;
      border: 7px solid #fff;

      img {
        bottom: 0;
      }
    }
    p {
      @include resize('margin-top', 32px);
      color: #0b6f38;

      strong {
        @include resize('margin-bottom', 14px);
        display: block;
        @include resize('font-size', 18px);
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
      }
      i {
        @include resize('width', 34px);
        @include resize('height', 34px);
        margin: 0 auto;
        @include resize('margin-top', 14px);
        display: block;
        font-style: normal;
        @include resize('font-size', 14px);
        font-weight: 600;
        @include resize('line-height', 35px);
        border-radius: 50%;
        color: #fff;
        background-color: #11974d;
      }
    }
  }
  .step1 {
    img {
      left: -13px;
    }
  }
  .step2 {
    img {
      left: -12px;
    }
  }
  .step3 {
    img {
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .media_group {
    margin-top: 64px;
  }
}

.real_plan,
.real_number {
  h2 {
    @include resize('font-size', 34px);

    strong {
      @include resize('font-size', 72px);
      font-weight: 500;
    }
  }
  .bottom_section {
    @include resize-multiple(
      'padding',
      0,
      $gutter + $column,
      0,
      $gutter + $column
    );
    clear: both;

    strong {
      @include resize('width', $column * 5 + $gutter * 6);
      @include resize('min-height', 60px);
      display: inline-block;
      //color: #33ca70;
      font-weight: 400;
      vertical-align: middle;
    }
    a,
    button {
      float: right;
      @include resize('min-width', $column * 3 + $gutter * 1);
    }
  }
}
.real_plan {
  @include resize-multiple('padding', 114px, 0, 82px, 0);

  .top_section {
    position: relative;

    h2 {
      position: absolute;
      @include resize('top', 66px);
      @include resize('left', $column + $gutter);
    }
    .img_area {
      @include resize-multiple('padding', 0, 70px, 0, 70px);
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .bottom_section {
    @include resize('padding-top', 93px);

    p {
      @include resize-multiple('padding', 34px, 58px, 34px, 58px);
      background-color: #d6dee4;
      button,
      a {
        @include resize('margin-top', 20px);
      }
    }
    strong {
      //font-size: 16px;
      line-height: 1.4;

      span {
        display: block;
        @include resize('margin-bottom', 12px);
        @include resize('font-size', 24px);
      }
    }
  }
}
.real_number {
  @include resize-multiple('padding', 130px, 0, 90px, 0);
  background-color: rgba(217, 224, 228, 0.5);

  h2 {
    strong {
      @include resize('margin-top', 20px);
      display: block;
    }
  }
  .left_section {
    @include resize('width', $gutter * 7 + $column * 7);
  }
  .right_section {
    @include resize('width', $gutter * 4 + $column * 4);
    @include resize('padding-top', 110px);
    @include resize('margin-left', $column);
    float: right;
  }
  .bottom_section {
    @include resize('padding-top', 110px);

    p {
      @include resize-multiple('padding', 60px, $column, 50px, $column);
      background-color: #fff;
    }
    strong {
      @include resize('font-size', 24px);
      line-height: 1.2;
    }
    a {
      color: #fff;
    }
  }
  .row {
    font-size: 0;

    & + .row {
      @include resize('margin-top', 42px);
    }
    strong,
    i {
      color: $highlight-color;
    }
    i,
    span,
    em {
      line-height: 1.6;
    }
    i,
    em {
      font-style: normal;
    }
    span {
      @include resize('font-size', 18px);
    }
    strong {
      @include resize('width', $column * 3 + $gutter * 3);
      @include resize('padding-right', 10px);
      display: inline-block;
      @include resize('font-size', 96px);
      font-weight: 100;
      text-align: right;
      line-height: 1;

      i {
        @include resize('font-size', 85px);
        display: inline-block;
      }
    }
    p {
      @include resize('width', $column * 4 + $gutter * 4);
      @include resize('padding-left', 10px);
      display: inline-block;
    }
    span {
      @include resize('margin-bottom', 6px);
      display: block;
      border-bottom: 1px solid $highlight-color;
    }
    em {
      @include resize('font-size', 14px);
      color: #535a60;
    }
  }
  .btn_trial {
    background-color: #000;
    color: #fff;
    &:before {
      @include retina-sprite($common-icon-link-white-group);
    }
  }
}

.real_story {
  h2 {
    text-align: center;
    color: #3ad478;
    @include resize('font-size', 34px);
  }
  .inner {
    @include resize-multiple(
      'padding',
      0,
      $column + $gutter,
      0,
      $column + $gutter
    );
  }
  .vision {
    @include resize-multiple('padding', 134px, 0, 83px, 0);
    text-align: center;

    img {
      display: block;
    }
    p {
      @include resize('font-size', 18px);
      color: #8c8d8f;
      line-height: 1.5;
    }
    .img_group {
      @include resize('margin-top', 70px);
      img {
        margin: 0 auto;
      }
    }
  }
  h3 {
    @include resize('margin-top', 45px);
    color: #00c047;
    @include resize('font-size', 20px);
    line-height: 1.4;

    strong {
      display: block;
      @include resize('font-size', 26px);
    }
    span {
      @include resize('width', 448px);
      @include resize('height', 124px);
      margin: 0 auto;
      @include resize('margin-top', 30px);
      display: block;
      background-color: #1c94c4;
    }
    em {
      margin-top: 10px;
      display: block;
      font-size: 11px;
      font-style: normal;
      color: #c1c3c7;
    }
  }
  .media_group {
    @include resize('width', $column * 6 + $gutter * 5);
    @include resize-multiple('padding', 0, 30px, 0, 30px);
    margin: 0 auto;
    @include resize('margin-top', 78px);
    @include resize('margin-bottom', 106px);
    img {
      width: 100%;
      height: auto;
    }
    .content_box {
      position: relative;
      .img_area {
        width: 225px;
        height: 225px;
        margin: 0 auto 20px;
        display: block;
        //border: 10px solid;
      }
      strong {
        font-size: 18px;
      }
      & + .content_box {
        &:before {
          content: '';
          position: absolute;
          top: 100px;
          left: -74px;
          @include retina-sprite($common-icon-class-arrow-group);
        }
      }
    }
    .left_section {
      .img_area {
        color: #5b5b5b;
      }
      strong {
        color: #000;
      }
    }
    .right_section {
      .img_area {
        color: $highlight-color;
      }
      strong {
        color: $highlight-color;
      }
    }
  }
  .img_group {
    img {
      width: auto;
      height: 170px;
      //@include resize('width', 469px);
      //height: auto;
    }
  }
  .review_section {
    background-color: #f8f8f9;

    .inner {
      @include resize-multiple(
        'padding',
        56px,
        $column * 2 + $gutter * 2,
        50px,
        $column * 2 + $gutter * 2
      );
    }
    .review_box {
      &:before {
        display: none;
      }
    }
    .txt_group {
      strong {
        @include resize('margin-bottom', 12px);
        display: block;
      }
    }
    .img_group {
      em {
        margin-left: 0;
      }
    }
  }
}
.real_question {
  @include resize-multiple('padding', 78px, 0, 62px, 0);
  background-color: #3ad478;

  .inner {
    @include resize-multiple(
      'padding',
      0,
      $gutter / 2 + $column * 2,
      0,
      $gutter / 2 + $column * 2
    );
    font-size: 0;
  }
  h2 span {
    @include resize('font-size', 18px);
  }
  ul {
    width: 49.9%;
    @include resize('margin-top', 40px);
    display: inline-block;
    font-size: 0;
    text-align: left;
    vertical-align: top;
  }
  li {
    @include resize('margin-bottom', 9px);
    @include resize-multiple('padding', 0, $gutter/2, 0, $gutter/2);
    font-size: 13px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &.open {
      .qna_body {
        display: block;
      }
    }
  }
  .qna_box {
    background-color: rgba(63, 188, 113, 0.5);
  }
  .qna_header {
    @include resize-multiple('padding', 13px, 12px, 12px, 48px);
    position: relative;
    background-color: rgba(63, 188, 113, 1);
    color: #fff;
    line-height: 1.4;

    &:before {
      content: '';
      @include resize('width', 22px);
      height: 3px;
      position: absolute;
      @include resize('top', 20px);
      @include resize('left', 15px);
      background-color: #79dba0;
    }
  }
  .qna_body {
    display: none;
    @include resize-multiple('padding', 15px, 32px, 18px, 48px);
    line-height: 1.4;

    a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid;
      cursor: pointer;
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}

.real_help {
  @include resize('padding-top', 76px);

  .inner {
    text-align: center;
  }

  h3 {
    @include resize('margin-bottom', 28px);
    text-align: center;
    @include resize('font-size', 26px);
  }
  p {
    line-height: 1.4;
    color: #687980;
  }
  .bottom_section {
    @include resize('margin-top', 20px);
    img {
      width: 100%;
      height: auto;
    }
  }
  button {
    display: inline-block;
    @include resize('margin-top', 18px);
  }
  img {
    display: block;
    margin: 0 auto;
  }
}

// common

.dot {
  position: relative;
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    margin-left: -2px;
    position: absolute;
    top: -6px;
    left: 50%;
    border-radius: 50%;
    background-color: $highlight-color;
  }
}
.btn_contact {
  padding: 0 49px;
  border: 2px solid;
  background-color: #fff;
  color: $highlight-color;
  line-height: 46px;
  font-size: 18px;

  &:before {
    content: '';
    margin-right: 24px;
    display: inline-block;
    @include retina-sprite($common-icon-link-green-group);
    vertical-align: middle;
  }
}
.btn_link {
  display: block;
  padding: 0 32px;
  line-height: 50px;
  font-size: 16px;

  &:before {
    margin-right: 19px;
  }
}
.btn_trial {
  padding: 0 68px;
  border: none;
  line-height: 60px;
  font-size: 18px;

  &:before {
    margin-right: 30px;
  }
}

.review_box {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    @include retina-sprite($common-bg-quotes-group);
  }

  .img_group {
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.review_section {
  @include resize-multiple(
    'padding',
    0,
    $column + $gutter,
    0,
    $column + $gutter
  );
  font-size: 0;

  .review_box {
    width: 50%;
    @include resize('padding-left', 15px);
    display: inline-block;
    vertical-align: top;
    position: relative;

    &:before {
      @include resize('top', -27px);
      @include resize('left', 10px);
    }
  }
  .txt_group {
    @include resize('padding-bottom', 26px);
    @include resize('margin-bottom', 15px);
    @include resize('font-size', 16px);
    line-height: 1.4;
    border-bottom: 1px solid rgba(111, 117, 125, 0.5);
  }
  .img_group {
    @include resize('min-height', 50px);
    @include resize('padding-left', 5px);
    position: relative;

    strong {
      margin-bottom: 2px;
      display: block;
      font-size: 13px;
    }
    em {
      @include resize('margin-left', 14px);
      font-size: 11px;
      font-style: normal;
    }
  }
  .img_round {
    @include resize('width', 50px);
    @include resize('height', 50px);
  }
}
.icon_qualified {
  display: inline-block;
  @include retina-sprite($common-icon-qualified2-group);

  &:before {
    content: '인증됨';
    @extend .a11y-hidden;
  }
}
.jw_player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.slope {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 109%;
    height: 101%;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: rotate(-9deg);
    -moz-transform: rotate(-9deg);
    -ms-transform: rotate(-9deg);
    -o-transform: rotate(-9deg);
    transform: rotate(-9deg);
  }
}

.popup_contract {
  .pop_inner {
    width: 620px;
    margin: 0 auto;
    position: relative;
    background-color: #fff;
  }
  .pop_body {
    border-radius: 3px;
    box-shadow: 2px 0 7px rgba(0, 0, 0, 0.56);
    overflow: hidden;

    .img_group {
      display: block;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  .btn_close_popup {
    position: absolute;
    top: 10px;
    right: -40px;
    background-color: transparent;
    border: none;
    @include retina-sprite($common-btn-close-popup-white-group);
  }
}
.duplicate_error_page {
  .main {
    padding: 70px 0;
    text-align: center;
  }
  h2 {
    padding-top: 108px;
    background: url('https://cf.realclass.co.kr/qualson/images/bg_duplicate_error.png')
      no-repeat center top;
    background-size: 20px auto;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
  }
  p {
    margin-top: 32px;
    font-size: 14px;
    line-height: 1.4;
  }
  button {
    font-size: 15px;
  }
  .btn_bg_white {
    color: #000;
    border: 2px solid;
  }
  .btn_bg_black {
    color: #2bde73;
  }
  .btn_group {
    margin-top: 45px;

    button + button {
      margin-top: 12px;
    }
  }
}

@media (min-width: 720px) {
  .duplicate_error_page {
    .main {
      padding: 105px 0;
    }
    h2 {
      padding-top: 200px;
      background-image: url('https://cf.realclass.co.kr/qualson/images/bg_duplicate_error_pc.png');
      background-size: 143px auto;
      font-size: 22px;
    }
    p {
      font-size: 16px;
    }
    button {
      height: 55px;
      font-size: 16px;
      line-height: 53px;
    }
    .btn_group {
      margin-top: 30px;

      button + button {
        margin-top: 0;
        margin-left: 8px;
      }
    }
  }
}
.error_page {
  .header {
    color: rgba(256, 256, 256, 0.8);

    h1 {
      a {
        @include retina-sprite($common-logo-white-group);
      }
    }
  }
  .main {
    padding-top: 80px;
    text-align: center;

    img {
      display: block;
      margin: 0 auto 40px;
    }
    h2 {
      margin-top: 136px;
      font-size: 22px;
    }
    button {
      margin-top: 48px;
      color: $highlight-color;
    }
  }
}
.jw_full {
  .header,
  .floating_banner {
    z-index: -1;
  }
  .real_video {
    .pagination,
    .controller button,
    .contract_info_group {
      z-index: -1;
    }
  }
  .full_banner .pagination_group,
  .chrome_download_banner {
    z-index: -1;
  }
}

// faq page
.faq_page {
  .main {
    min-height: 100%;
    padding-top: 70px;

    .inner {
      max-width: 710px;
    }
  }
  .article {
    padding: 34px 0 283px;

    h2 {
      font-size: 20px;
    }
  }
  .faq_title {
    margin-bottom: 20px;
    text-align: center;
    h2 {
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
    }
    .channel_btn {
      width: 240px;
      height: 50px;
      margin: 20px 0 30px;
      color: #fff;
      font-size: 16px;
      border: 0;
      background: #000;
    }
  }
  .local_nav {
    ul {
      margin: 0 -10px;
      font-size: 0;
    }
    li {
      width: 49.9%;
      padding: 7px 10px;
      display: inline-block;

      &.on {
        a {
          background-color: #2bde73;
          color: #fff;
        }
      }
      a {
        display: block;
        font-size: 16px;
        color: #2bde73;
        text-decoration: none;
        border: 1px solid #2bde73;
        text-align: center;
        line-height: 56px;
        cursor: pointer;
      }
    }
  }
  .content {
    margin-top: 34px;

    ul {
      margin-top: 10px;
      font-size: 16px;
    }
    li {
      border-bottom: 1px solid #e1e1e1;
    }
    .open {
      .list_body {
        display: block;
      }
      .list_head {
        &:after {
          display: none;
        }
      }
    }
    .tag {
      min-width: 80px;
      max-width: 86px;
      padding: 3px 2px 2px;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      font-size: 12px;
      white-space: nowrap;
      color: #fff;
      background-color: #2bde73;
      border-radius: 30px;
      text-align: center;
      font-style: normal;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .list_head {
      position: relative;
      padding: 17px 60px 17px 90px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        @include retina-sprite($common-icon-toggle-group);
        right: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      * {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
      span {
        display: inline-block;
      }
    }
    .list_body {
      padding: 10px 0 20px;
      display: none;
      line-height: 1.5;
      color: #4d4d4d;

      a {
        display: inline-block;
        color: inherit;
        //text-decoration: none;
        //border-bottom: 1px solid;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  .footer {
    margin-top: -210px;
  }
}

.popup_coupon {
  .popup_coupon_inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 447px;
    height: 570px;
    background-image: url('https://cf.realclass.co.kr/qualson/images/popup-bg@2x.png');
    background-size: cover;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .coupon_header {
    width: 317px;
    height: 96px;
    margin: 48px auto 26px;
  }

  .coupon_content {
    width: 324px;
    height: 209px;
    border-radius: 10px;
    box-shadow: 1.6px 2.5px 0 0 rgba(12, 11, 10, 0.15);
    border: solid 2px #ebebeb;
    background-color: #ffffff;
    margin: 0 auto;
    margin-left: 65px;

    .top {
      background: #f2f3f5;
      height: 56.6px;
      display: flex;
      align-items: center;
      .title {
        height: 21px;
        margin: 0 auto;
      }
      .title-1 {
        width: 169px;
      }
      .title-2 {
        width: 224px;
      }
      .title-3 {
        width: 205px;
      }
    }

    .bottom {
      margin-top: 24px;
      .percent {
        height: 100px;
        margin: 0 auto;
      }
      .percent-DISCOUNT_5,
      .percent-DISCOUNT_7 {
        width: 127px;
      }

      .percent-DISCOUNT_10,
      .percent-DISCOUNT_15 {
        width: 147px;
      }

      .percent-DISCOUNT_35 {
        width: 137px;
      }

      .percent-DISCOUNT_40,
      .percent-DISCOUNT_45 {
        width: 138px;
      }

      .percent-DISCOUNT_100000WON {
        width: 193px;
      }
    }
  }

  .time {
    width: 196px;
    height: 29px;
    background: #ff414d;
    margin: 18px auto 38px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 17.5px;
      color: #fff;
    }
  }

  .btn-coupon {
    width: 356px;
    height: 57px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    margin: 0 auto;
  }

  .guide-text {
    font-size: 15.5px;
    color: #000;
    display: flex;
    justify-content: center;
    margin-top: 14px;
  }

  .btn_close_popup {
    position: absolute;
    @include retina-sprite($common-btn-close-popup-white-group);
    top: 0;
    right: -10px;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    background-color: transparent;
  }
}
