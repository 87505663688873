@charset "UTF-8";
/*! Copyright Qualson inc. */
@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_light_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_light_subset.woff) format("woff"); }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_regular_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_regular_subset.woff) format("woff"); }

@font-face {
  font-family: 'AppleSDGothicNeo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("AppleSDGothicNeo"), url(../fonts/AppleSDGothicNeo_bold_subset.woff2) format("woff2"), url(../fonts/AppleSDGothicNeo_bold_subset.woff) format("woff"); }

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

body {
  margin: 0;
  font-size: 16px;
  font-family: 'AppleSDGothicNeo', AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', 'Apple SD Gothic Neo', sans-serif;
  color: #000; }
  body br {
    font-family: AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', 'Apple SD Gothic Neo', sans-serif; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
span {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default; }

table {
  border-collapse: collapse; }

button {
  border-radius: 0;
  background-color: transparent; }

select,
button {
  cursor: pointer;
  outline: none; }
  select *,
  button * {
    cursor: pointer;
    outline: none; }

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  color: #000; }
  input[type='text']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  input[type='tel']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #858a91; }
  input[type='text']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  input[type='tel']::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #858a91; }
  input[type='text']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  input[type='tel']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #858a91; }
  input[type='text']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='password']:-moz-placeholder,
  input[type='tel']:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #858a91; }

input[type='text'],
input[type='submit'],
input[type='search'] {
  margin: 0;
  outline: none; }

input[type='submit'] {
  cursor: pointer; }

input:active,
input:focus {
  outline: none; }

a[role='button'] {
  cursor: pointer;
  outline: none; }
  a[role='button'] * {
    cursor: pointer;
    outline: none; }

select::-ms-expand {
  display: none; }

a[role='button'] {
  text-decoration: none;
  color: inherit;
  outline: none; }

img {
  border: none;
  -webkit-touch-callout: none; }

input[type='search']::-webkit-search-decoration, input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-results-button, input[type='search']::-webkit-search-results-decoration {
  display: none; }

select[disabled='disabled'] {
  cursor: default !important; }

.a11y-hidden, .icon_qualified:before {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }
  .a11y-hidden.focusable, .focusable.icon_qualified:before {
    overflow: visible;
    position: static;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.ir {
  text-indent: -9999px;
  overflow: hidden; }

.txtL {
  text-align: left; }

.txtR {
  text-align: right; }

html,
body,
.wrap {
  width: 100%;
  height: 100%; }

.inner {
  width: 1280px;
  margin: 0 auto;
  position: relative; }

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501; }

.modal {
  overflow: hidden; }
  .modal:after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 502; }

[class^='popup_'] {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  background-color: rgba(0, 0, 0, 0.2); }
  [class^='popup_'].show {
    display: block; }
  [class^='popup_'] .inner {
    height: 100%; }
  [class^='popup_'].scroll_view {
    overflow-y: scroll; }
    [class^='popup_'].scroll_view .inner {
      height: auto;
      min-height: 100%;
      padding-top: 110px;
      padding-bottom: 110px; }
    [class^='popup_'].scroll_view .pop_inner {
      margin: 0 auto;
      max-width: 100%; }
  [class^='popup_'].float_view .pop_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); }
  [class^='popup_'] .btn_pop {
    padding: 22px 0 21px;
    background-color: #5900fd;
    color: #fff;
    font-size: 22px;
    border: none; }
    [class^='popup_'] .btn_pop.primary {
      background-color: #aaaeb2; }
  [class^='popup_'] .pop_title {
    border-bottom: 1px solid; }
  [class^='popup_'] .btn_close_popup {
    width: 20px;
    height: 20px;
    border: none; }

.img_round {
  border-radius: 50%;
  overflow: hidden;
  display: inline-block; }
  .img_round img {
    display: block; }

.slides {
  position: relative; }
  .slides .controller.btn_black span {
    background-image: url(../images/sprite/common.png);
    background-position: -1338px -495px;
    width: 30px;
    height: 53px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_black span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_fill span {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -1221px;
    width: 11px;
    height: 19px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_fill span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_fill:hover span {
    background-image: url(../images/sprite/common.png);
    background-position: -1396px -1187px;
    width: 11px;
    height: 19px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_fill:hover span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller button {
    position: absolute;
    display: none;
    border: none;
    background-color: transparent; }
    .slides .controller button.on {
      display: block; }
  .slides .controller span {
    display: inline-block; }
  .slides .controller .btn_next span {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.pad0 {
  padding: 0 !important; }

.header,
.page_sub_header {
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 70px; }
  .header .inner,
  .page_sub_header .inner {
    min-width: 1024px;
    padding: 0 70px;
    width: 100%; }

.header {
  color: #434449;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }
  .header h1,
  .header .nav_container {
    line-height: 70px; }
  .header h1 {
    left: 70px;
    position: absolute;
    top: 0; }
    .header h1 a {
      background: url("https://cf.realclass.co.kr/qualson/images/logo_w@2x.png") no-repeat center;
      background-size: 120px auto;
      display: inline-block;
      height: 68px;
      vertical-align: top;
      width: 120px; }
      .header h1 a:active {
        background-image: url("https://cf.realclass.co.kr/qualson/images/logo_green@2x.png"); }
  .header a {
    color: inherit;
    text-decoration: none; }
    .header a:active {
      color: #2bde73; }

.nav_container {
  padding-left: 170px; }
  .nav_container .main_nav {
    float: left; }
    .nav_container .main_nav li + li {
      margin-left: 5px; }
    .nav_container .main_nav a {
      cursor: pointer;
      display: block;
      padding: 0 18px; }
    .nav_container .main_nav > li > a strong {
      font-size: 16px; }
  .nav_container .sub_nav {
    float: right; }
    .nav_container .sub_nav li + li {
      margin-left: 20px; }
    .nav_container .sub_nav a {
      cursor: pointer;
      display: block; }
  .nav_container ul {
    font-size: 0; }
  .nav_container li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    vertical-align: top; }
    .nav_container li.on a {
      color: #2bde73; }
    .nav_container li.on .class_menu:after {
      background-image: url(../images/sprite/common.png);
      background-position: -1158px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container li.on .class_menu:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
  .nav_container .bt_package {
    font-size: 16px;
    font-weight: 600; }
    .nav_container .bt_package span {
      border-bottom: 1px solid;
      border-top: 1px solid;
      display: inline-block;
      line-height: 1;
      padding: 6px 0 3px; }
  .nav_container .class_menu {
    font-size: 16px;
    font-weight: 600; }
    .nav_container .class_menu:after {
      content: '';
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
      background-image: url(../images/sprite/common.png);
      background-position: -1108px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container .class_menu:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .nav_container .class_menu:hover:after {
      background-image: url(../images/sprite/common.png);
      background-position: -1108px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container .class_menu:hover:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .nav_container .class_menu:active:after, .nav_container .class_menu.on:after {
      background-image: url(../images/sprite/common.png);
      background-position: -1158px -1274px;
      width: 10px;
      height: 7px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .nav_container .class_menu:active:after, .nav_container .class_menu.on:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .nav_container .class_menu.on {
      color: #2bde73; }
      .nav_container .class_menu.on:after {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
  .nav_container .class_menu.on + .float_nav {
    display: block; }
  .nav_container .hasSubmenu:hover + .float_nav {
    display: block; }
  .nav_container .float_nav:hover {
    display: block; }
  .nav_container .float_nav {
    background-color: #fff;
    border: 1px solid #00c047;
    border-radius: 5px;
    bottom: 5px;
    color: #000;
    display: none;
    position: absolute; }
    .nav_container .float_nav li {
      display: block; }
      .nav_container .float_nav li + li {
        margin-left: 0; }
  .nav_container .sub_my_menu {
    border: 1px solid #2bde73;
    left: 50%;
    -moz-transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    -o-transform: translate(-50%, 100%);
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
    min-width: 127px; }
    .nav_container .sub_my_menu li {
      font-size: 14px;
      line-height: 51px;
      text-align: center; }
      .nav_container .sub_my_menu li a {
        color: #000;
        white-space: nowrap; }
        .nav_container .sub_my_menu li a:active {
          color: #000; }
        .nav_container .sub_my_menu li a.onEvent:after {
          content: 'event';
          background-image: url(../images/sprite/common.png);
          background-position: -533px -1035px;
          width: 38px;
          height: 12px;
          overflow: hidden;
          text-indent: -999em;
          vertical-align: middle;
          margin-left: 7px;
          display: inline-block; }
          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            .nav_container .sub_my_menu li a.onEvent:after {
              background-image: url(../images/sprite/common@2x.png);
              background-size: 1459px 1423px; } }
      .nav_container .sub_my_menu li + li {
        border-top: 1px solid #e6e8ea;
        margin-left: 0; }
      .nav_container .sub_my_menu li:hover {
        background-color: #2bde73; }
  .nav_container .sub_class_menu {
    left: 0;
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%); }
    .nav_container .sub_class_menu .inner_wrap {
      display: table;
      min-width: 680px;
      padding: 26px 0 20px; }
    .nav_container .sub_class_menu h3,
    .nav_container .sub_class_menu li {
      font-size: 14px;
      line-height: 1.4; }
    .nav_container .sub_class_menu h3 {
      border-bottom: none;
      left: 0;
      padding: 0 10px 0 20px;
      position: absolute;
      top: 0;
      width: 140px; }
    .nav_container .sub_class_menu li a {
      border-bottom: 1px solid transparent;
      color: #757879;
      display: inline-block;
      padding: 0;
      white-space: nowrap; }
      .nav_container .sub_class_menu li a:hover {
        border-bottom: 1px solid #2bde73;
        color: #2bde73; }
    .nav_container .sub_class_menu li.on a {
      border-bottom: 1px solid;
      color: #2bde73; }
    .nav_container .sub_class_menu li + li {
      margin-top: 15px; }
    .nav_container .sub_class_menu .menu_box {
      display: table-cell;
      min-width: 366px;
      padding-left: 180px;
      padding-right: 41px;
      position: relative;
      width: 50%; }
      .nav_container .sub_class_menu .menu_box .img_area {
        bottom: -20px;
        left: 0;
        position: absolute; }
        .nav_container .sub_class_menu .menu_box .img_area img {
          display: block;
          height: auto;
          width: 180px; }
      .nav_container .sub_class_menu .menu_box + .menu_box {
        border-left: 1px solid #eff1f2; }
    .nav_container .sub_class_menu .lecture_menu h3 {
      width: 170px; }
    .nav_container .sub_class_menu .lecture_menu li {
      padding-left: 51px;
      position: relative; }
    .nav_container .sub_class_menu .lecture_menu a + a {
      margin-top: 14px; }
    .nav_container .sub_class_menu .lecture_menu .new {
      position: relative; }
      .nav_container .sub_class_menu .lecture_menu .new:before {
        background: url(https://cf.realclass.co.kr/qualson/images/icon_new_class@2x.png) no-repeat center;
        background-size: 100% auto;
        content: '';
        height: 17px;
        left: -12px;
        position: absolute;
        top: -11px;
        width: 25px; }
    .nav_container .sub_class_menu .lecture_menu .tag {
      border: 1px solid;
      border-radius: 8px;
      color: #757879;
      font-size: 10px;
      height: 16px;
      left: 1px;
      line-height: 16px;
      position: absolute;
      text-align: center;
      top: 1px;
      width: 33px; }

.page_sub_header {
  left: 0;
  position: fixed;
  top: 70px;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
  width: 100%;
  z-index: 500; }
  .page_sub_header .inner {
    overflow: hidden; }
  .page_sub_header .page_sub_title {
    float: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 70px; }
  .page_sub_header .sub_button {
    border: 1px solid;
    border-radius: 8px;
    color: inherit;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    height: 36px;
    line-height: 35px;
    position: absolute;
    right: 70px;
    text-align: center;
    text-decoration: none;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 86px; }
  .page_sub_header .user_tab {
    float: right; }
    .page_sub_header .user_tab li {
      color: #a5a7aa;
      float: left; }
      .page_sub_header .user_tab li + li {
        margin-left: 140px; }
      .page_sub_header .user_tab li.on {
        color: #000;
        font-weight: 600; }
        .page_sub_header .user_tab li.on a:after {
          background-color: #2bde73;
          border-radius: 50%;
          bottom: 15px;
          content: '';
          height: 7px;
          left: 50%;
          position: absolute;
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          width: 7px; }
    .page_sub_header .user_tab a {
      color: inherit;
      cursor: pointer;
      display: block;
      font-size: 16px;
      line-height: 70px;
      position: relative;
      text-decoration: none; }

.hide_header .header,
.hide_header .page_sub_header,
.hide_header .class_banner,
.hide_header .real_success_story .success_head,
.hide_header.pc_view .package_timer_group .timer_area.fixed_top .inner_timer_area {
  -moz-transform: translate3d(0, -70px, 0);
  -ms-transform: translate3d(0, -70px, 0);
  -o-transform: translate3d(0, -70px, 0);
  -webkit-transform: translate3d(0, -70px, 0);
  transform: translate3d(0, -70px, 0); }

.chrome_download_banner {
  left: 0;
  position: fixed;
  top: 100px;
  width: 100%;
  z-index: 10; }
  .chrome_download_banner .banner_box {
    background-color: #fff;
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.37);
    font-size: 14px;
    line-height: 1.3;
    padding: 12px 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 270px;
    z-index: 1; }
  .chrome_download_banner .btn_group {
    margin-top: 10px;
    text-align: right; }
    .chrome_download_banner .btn_group a,
    .chrome_download_banner .btn_group button {
      display: inline-block;
      font-size: 13px;
      padding: 5px 10px;
      vertical-align: middle; }
      .chrome_download_banner .btn_group a:hover,
      .chrome_download_banner .btn_group button:hover {
        background-color: #2bde73; }
      .chrome_download_banner .btn_group a:active,
      .chrome_download_banner .btn_group button:active {
        box-shadow: inset 1px 1px 3px -1px rgba(0, 0, 0, 0.28); }
    .chrome_download_banner .btn_group a {
      text-decoration: none; }
    .chrome_download_banner .btn_group button {
      background-color: transparent;
      border: none; }

.popup_primary .pop_inner {
  background-color: #fff;
  color: #000;
  width: 400px; }

.popup_primary .pop_body {
  padding: 48px 0 42px; }
  .popup_primary .pop_body p {
    font-size: 14px;
    line-height: 1.4;
    text-align: center; }
    .popup_primary .pop_body p strong {
      display: block;
      font-size: 16px;
      font-weight: 400; }
    .popup_primary .pop_body p i {
      display: block;
      font-size: 14px;
      font-style: normal;
      margin-top: 3px; }
  .popup_primary .pop_body p + p {
    margin-top: 22px; }
  .popup_primary .pop_body .alignCenter {
    text-align: center; }

.popup_primary .pop_foot {
  padding: 0 23px 23px; }
  .popup_primary .pop_foot .btn_group {
    font-size: 0;
    margin: 0 -6px; }
    .popup_primary .pop_foot .btn_group .col {
      display: inline-block;
      padding: 0 6px;
      width: 50%; }
    .popup_primary .pop_foot .btn_group .row {
      display: block; }
      .popup_primary .pop_foot .btn_group .row + .row {
        margin-top: 13px; }
    .popup_primary .pop_foot .btn_group a {
      display: block;
      text-align: center;
      text-decoration: none; }
    .popup_primary .pop_foot .btn_group button,
    .popup_primary .pop_foot .btn_group a {
      background-color: #fff;
      border: 1px solid;
      color: #000;
      display: block;
      font-size: 18px;
      height: 52px;
      line-height: 48px;
      padding: 0;
      width: 100%; }
      .popup_primary .pop_foot .btn_group button.btn_primary,
      .popup_primary .pop_foot .btn_group a.btn_primary {
        background-color: #000;
        border: 1px solid #000;
        color: #2bde73; }

.popup_bridge .pop_inner {
  background-color: #eaedf1;
  position: relative; }

.popup_bridge .bridge_main .bg {
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%; }
  .popup_bridge .bridge_main .bg img {
    height: auto;
    left: 50%;
    position: relative;
    top: 0;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%; }

.popup_bridge .bridge_main h1 {
  font-size: 22px;
  padding-left: 2px;
  position: relative; }

.popup_bridge .bridge_main .main_banner {
  position: relative; }
  .popup_bridge .bridge_main .main_banner .thumbnail_group {
    bottom: 32px;
    font-size: 0;
    left: 20px;
    margin-top: 15px;
    position: absolute; }
  .popup_bridge .bridge_main .main_banner .img_round {
    border: 2px solid #fff;
    height: 54px;
    width: 54px; }
    .popup_bridge .bridge_main .main_banner .img_round img {
      height: 100%;
      width: 100%; }

.popup_bridge .bridge_main .inner {
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 640px;
  padding: 30px 20px 32px 25px;
  position: absolute;
  top: 0;
  width: 100%; }

.popup_bridge .bridge_main .class_list {
  margin-top: -16px;
  padding: 0 10px 8px;
  position: relative; }
  .popup_bridge .bridge_main .class_list li {
    background-color: #fff;
    border: 1px solid #ebebeb;
    position: relative; }
  .popup_bridge .bridge_main .class_list a {
    color: inherit;
    display: block;
    min-height: 122px;
    padding: 28px 174px 16px 20px;
    text-decoration: none; }
  .popup_bridge .bridge_main .class_list h2 {
    font-size: 18px; }
    .popup_bridge .bridge_main .class_list h2 strong {
      color: #00c047; }
    .popup_bridge .bridge_main .class_list h2 em {
      background-color: #00c047;
      border-radius: 10px;
      color: #fff;
      display: inline-block;
      font-size: 10px;
      font-style: normal;
      padding: 2px 5px;
      vertical-align: middle; }
  .popup_bridge .bridge_main .class_list .img_area {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto; }
    .popup_bridge .bridge_main .class_list .img_area img {
      display: block;
      height: 100%;
      width: auto; }
  .popup_bridge .bridge_main .class_list p {
    font-size: 13px;
    margin-top: 8px; }
  .popup_bridge .bridge_main .class_list li + li {
    margin-top: 3px; }

.popup_bridge .bridge_main .main_foot {
  background-color: #00c047;
  padding: 19px 20px;
  width: 100%; }
  .popup_bridge .bridge_main .main_foot .num {
    font-size: 17px; }
  .popup_bridge .bridge_main .main_foot .txt_group {
    float: left; }
  .popup_bridge .bridge_main .main_foot .btn_group {
    float: right; }
  .popup_bridge .bridge_main .main_foot h3 {
    font-size: inherit;
    font-weight: 400;
    margin-top: 8px; }
  .popup_bridge .bridge_main .main_foot .info_soldout {
    color: #ff414d;
    display: none; }
  .popup_bridge .bridge_main .main_foot button {
    border: none;
    font-size: inherit;
    height: 43px; }
  .popup_bridge .bridge_main .main_foot .btn_trial {
    background-color: #000;
    color: #2bde73;
    width: 140px; }
  .popup_bridge .bridge_main .main_foot .btn_signup {
    background-color: #ff414d;
    color: #fff;
    display: none;
    width: 125px; }
  .popup_bridge .bridge_main .main_foot.soldout {
    background-color: #b7b7b7; }
    .popup_bridge .bridge_main .main_foot.soldout .info_soldout,
    .popup_bridge .bridge_main .main_foot.soldout .btn_signup {
      display: block; }
    .popup_bridge .bridge_main .main_foot.soldout .info_trial,
    .popup_bridge .bridge_main .main_foot.soldout .btn_trial {
      display: none; }

.popup_bridge .inner .pop_inner,
.popup_signup_request .inner .pop_inner {
  width: 480px; }

.popup_bridge .pop_inner,
.popup_signup_request .pop_inner {
  position: relative; }

.popup_bridge .btn_close_popup,
.popup_signup_request .btn_close_popup {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  right: -50px;
  top: 0;
  width: 50px; }
  .popup_bridge .btn_close_popup:before,
  .popup_signup_request .btn_close_popup:before {
    content: '';
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -46px -1346px;
    width: 31px;
    height: 31px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_bridge .btn_close_popup:before,
      .popup_signup_request .btn_close_popup:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.popup_absent_remain .pop_head {
  font-size: 17px;
  font-weight: 600;
  padding: 23px 0 0;
  text-align: center; }

.popup_absent_remain .pop_body {
  line-height: 1.5; }

.popup_challenge_info .btn_close_popup {
  background-color: transparent;
  border: none;
  height: 50px;
  position: absolute;
  right: -50px;
  top: 0;
  width: 50px; }
  .popup_challenge_info .btn_close_popup:before {
    content: '';
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -46px -1346px;
    width: 31px;
    height: 31px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_challenge_info .btn_close_popup:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.popup_challenge_info .pop_inner {
  background-color: #fff;
  border-radius: 2px;
  position: relative;
  width: 550px; }

.popup_challenge_info .pop_head {
  border-bottom: 2px solid #2bde73;
  padding: 16px 0 12px;
  text-align: center; }

.popup_challenge_info .pop_body {
  font-size: 14px;
  line-height: 1.4;
  padding: 31px 32px 37px; }
  .popup_challenge_info .pop_body h4 {
    border-bottom: 2px solid #828487;
    font-size: 15px;
    margin: 35px 0 8px;
    padding: 0 2px; }
  .popup_challenge_info .pop_body ol,
  .popup_challenge_info .pop_body ul {
    margin-top: 14px; }
  .popup_challenge_info .pop_body li {
    padding-left: 9px;
    position: relative; }
    .popup_challenge_info .pop_body li:before {
      content: '-';
      left: 2px;
      position: absolute;
      top: 1px; }
    .popup_challenge_info .pop_body li + li {
      margin-top: 2px; }
  .popup_challenge_info .pop_body em {
    font-style: normal;
    font-weight: 600;
    position: relative; }
    .popup_challenge_info .pop_body em:before {
      background-color: rgba(255, 245, 104, 0.5);
      bottom: 0;
      content: '';
      height: 70%;
      left: 0;
      position: absolute;
      width: 100%; }
    .popup_challenge_info .pop_body em span {
      position: relative; }

.popup_signup_request .pop_inner {
  background-color: #fff; }

.popup_signup_request .pop_body {
  text-align: center; }
  .popup_signup_request .pop_body h2 {
    font-size: 22px;
    line-height: 1.36; }
  .popup_signup_request .pop_body h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.36;
    margin-top: 32px; }
    .popup_signup_request .pop_body h3 span {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      display: inline-block;
      padding: 2px; }
  .popup_signup_request .pop_body ul {
    margin: 20px auto 0;
    text-align: center; }
  .popup_signup_request .pop_body li {
    display: inline-block;
    min-height: 54px;
    padding: 66px 0 0;
    position: relative;
    vertical-align: top;
    width: 114px; }
    .popup_signup_request .pop_body li strong {
      display: block;
      font-size: 18px;
      font-weight: 400; }
    .popup_signup_request .pop_body li span {
      color: #626569;
      font-size: 14px; }
    .popup_signup_request .pop_body li:before {
      content: '';
      left: 50%;
      position: absolute;
      top: 0;
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%); }
  .popup_signup_request .pop_body .top_section {
    height: 372px;
    overflow: hidden;
    position: relative; }
  .popup_signup_request .pop_body .bottom_section {
    padding: 38px 0; }
  .popup_signup_request .pop_body .real_free:before {
    background-image: url(../images/sprite/common.png);
    background-position: -312px -1190px;
    width: 58px;
    height: 55px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_free:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .real_event:before {
    background-image: url(../images/sprite/common.png);
    background-position: -385px -1190px;
    width: 58px;
    height: 55px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_event:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .real_save:before {
    background-image: url(../images/sprite/common.png);
    background-position: -458px -1190px;
    width: 57px;
    height: 55px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .popup_signup_request .pop_body .real_save:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .popup_signup_request .pop_body .img_area img {
    display: block;
    height: auto;
    width: 100%; }
  .popup_signup_request .pop_body .btn_group {
    bottom: 0;
    left: 0;
    margin-top: 72px;
    padding-bottom: 12px;
    position: absolute;
    width: 100%; }
    .popup_signup_request .pop_body .btn_group .btn_signup {
      display: block;
      font-weight: 500;
      margin: 0 auto 14px;
      width: 215px; }
    .popup_signup_request .pop_body .btn_group .btn_login {
      color: #fff;
      font-size: 14px;
      text-decoration: none; }
      .popup_signup_request .pop_body .btn_group .btn_login strong {
        border-bottom: 1px solid;
        color: #2bde73;
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        line-height: 1.2; }

.slides {
  position: relative; }
  .slides .controller.btn_white span {
    background-image: url(../images/sprite/common.png);
    background-position: -1338px 0px;
    width: 43px;
    height: 82px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_white span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_gray span {
    background-image: url(../images/sprite/common.png);
    background-position: -1338px -289px;
    width: 40px;
    height: 71px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_gray span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .slides .controller.btn_small span {
    background-image: url(../images/sprite/common.png);
    background-position: -1433px -1137px;
    width: 16px;
    height: 25px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .slides .controller.btn_small span {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.footer {
  background-color: #eceff0;
  color: #878c8d;
  font-size: 11px;
  padding: 34px 0 72px;
  position: relative;
  text-align: left; }
  .footer .inner {
    padding: 0 60px;
    width: 1024px; }
  .footer h5,
  .footer h6 {
    font-weight: 400; }
  .footer h5 {
    font-size: 0; }
    .footer h5 * {
      font-size: 11px; }
    .footer h5 strong {
      font-weight: 500; }
    .footer h5 a {
      color: inherit;
      text-decoration: none; }
    .footer h5 + h5 {
      margin-top: 5px; }
  .footer h6 {
    font-size: 11px;
    margin-top: 17px; }
    .footer h6 a {
      color: inherit;
      cursor: pointer;
      text-decoration: none; }
  .footer i {
    font-style: normal;
    margin: 0 6px; }
  .footer .txt_legal_line {
    border-top: 1px solid #dde0e1;
    margin-top: 13px;
    padding-top: 16px; }

.bt_black {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid;
  color: #000; }
  .bt_black:hover, .bt_black:active {
    background-color: rgba(0, 0, 0, 0.8);
    border: 2px solid rgba(0, 0, 0, 0.8);
    color: #fff; }

.btn_link,
.btn_bg_white,
.btn_bg_green,
.btn_bg_black {
  border: none;
  font-size: 15px;
  height: 50px;
  line-height: 49px;
  min-width: 230px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none; }

.btn_link:before {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: 16px;
  vertical-align: middle;
  width: 40px; }

.btn_bg_white,
.link_white {
  background-color: #fff;
  color: #2bde73; }
  .btn_bg_white:hover,
  .link_white:hover {
    background-color: #f7f8f8; }
  .btn_bg_white:active,
  .link_white:active {
    background-color: #e6e9ea; }

.btn_bg_green,
.link_green {
  background-color: #2bde73;
  color: #000; }
  .btn_bg_green:hover,
  .link_green:hover {
    background-color: #2ebd68; }
  .btn_bg_green:active,
  .link_green:active {
    background-color: #25ad5c; }

.btn_bg_black,
.link_black {
  background-color: #000;
  color: #fff; }
  .btn_bg_black:hover,
  .link_black:hover {
    background-color: #222; }
  .btn_bg_black:active,
  .link_black:active {
    background-color: #333; }

.link_icon_white {
  color: #fff; }
  .link_icon_white:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1069px -1190px;
    width: 40px;
    height: 20px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_white:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_icon_green {
  color: #2bde73; }
  .link_icon_green:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1124px -1190px;
    width: 40px;
    height: 20px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_green:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_icon_black {
  color: #000; }
  .link_icon_black:before {
    background-image: url(../images/sprite/common.png);
    background-position: -1179px -1190px;
    width: 40px;
    height: 20px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .link_icon_black:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.link_white:before {
  background: url("https://cf.realclass.co.kr/images/btn_white@2x.gif") no-repeat center;
  background-size: 40px auto; }

.link_white:hover:before, .link_white:active:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1124px -1190px;
  width: 40px;
  height: 20px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_white:hover:before, .link_white:active:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.link_green:before {
  background: url("https://cf.realclass.co.kr/images/btn_green@2x.gif") no-repeat center;
  background-size: 40px auto; }

.link_green:hover:before, .link_green:active:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1179px -1190px;
  width: 40px;
  height: 20px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_green:hover:before, .link_green:active:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.link_black:before {
  background: url("https://cf.realclass.co.kr/images/btn_black@2x.gif") no-repeat center;
  background-size: 40px auto; }

.link_black:hover:before, .link_black:active:before {
  background-image: url(../images/sprite/common.png);
  background-position: -1069px -1190px;
  width: 40px;
  height: 20px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .link_black:hover:before, .link_black:active:before {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.bt_animation {
  background-color: transparent;
  border: none;
  display: block;
  height: 50px; }
  .bt_animation img {
    display: block; }

.btn_link.bt_animation {
  padding: 0; }
  .btn_link.bt_animation:before {
    display: none; }

.floating_banner {
  background-color: rgba(240, 240, 240, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 600; }
  .floating_banner .inner {
    height: 60px;
    text-align: center; }
  .floating_banner .img_area,
  .floating_banner .txt_area {
    display: inline-block;
    vertical-align: middle; }
  .floating_banner .img_area {
    height: 100%;
    position: relative;
    width: 92px; }
    .floating_banner .img_area img {
      bottom: 0;
      height: auto;
      left: 0;
      position: absolute;
      width: 100%; }
  .floating_banner .txt_area {
    color: #000;
    font-size: 18px; }
    .floating_banner .txt_area b {
      border-bottom: 1px solid;
      color: #2bde73;
      display: inline-block;
      font-weight: 400;
      margin: 0 4px; }
    .floating_banner .txt_area i {
      font-style: normal; }
  .floating_banner a {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.legal_line {
  bottom: 5px;
  color: #000;
  font-size: 10px;
  left: 50%;
  position: absolute;
  text-align: center;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.browser_not_supported .inner {
  max-width: 480px;
  padding: 87px 0;
  text-align: center; }

.browser_not_supported h5 {
  background: url("https://cf.realclass.co.kr/qualson/images/bg_browser_not_supported@2x.png") no-repeat center top;
  background-size: 73px auto;
  font-size: 22px;
  padding-top: 118px; }

.browser_not_supported p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 27px; }
  .browser_not_supported p strong {
    background-color: #eef0f1;
    border-radius: 7px;
    display: block;
    margin: 19px auto 20px;
    padding: 22px 0;
    width: 312px; }
  .browser_not_supported p span {
    color: #19bf5c; }

.browser_not_supported a {
  background-color: #000;
  color: #2bde73;
  display: block;
  height: 55px;
  line-height: 55px;
  margin: 40px auto 0;
  text-decoration: none;
  width: 260px; }

.popup_mission2 .pop_inner,
.popup_mission3 .pop_inner,
.popup_mission_final .pop_inner {
  padding-top: 20px !important;
  width: 621px; }

.popup_refund_info,
.popup_apply_success,
.popup_mission,
.popup_marketing,
.popup_mission2,
.popup_mission3,
.popup_mission_final {
  display: none; }
  .popup_refund_info.show,
  .popup_apply_success.show,
  .popup_mission.show,
  .popup_marketing.show,
  .popup_mission2.show,
  .popup_mission3.show,
  .popup_mission_final.show {
    display: block; }
  .popup_refund_info .pop_inner,
  .popup_apply_success .pop_inner,
  .popup_mission .pop_inner,
  .popup_marketing .pop_inner,
  .popup_mission2 .pop_inner,
  .popup_mission3 .pop_inner,
  .popup_mission_final .pop_inner {
    padding-top: 36px;
    text-align: center; }
  .popup_refund_info .pop_head h2,
  .popup_apply_success .pop_head h2,
  .popup_mission .pop_head h2,
  .popup_marketing .pop_head h2,
  .popup_mission2 .pop_head h2,
  .popup_mission3 .pop_head h2,
  .popup_mission_final .pop_head h2 {
    color: #000;
    font-size: 26px;
    font-weight: bold; }
  .popup_refund_info .pop_head h3,
  .popup_apply_success .pop_head h3,
  .popup_mission .pop_head h3,
  .popup_marketing .pop_head h3,
  .popup_mission2 .pop_head h3,
  .popup_mission3 .pop_head h3,
  .popup_mission_final .pop_head h3 {
    font-size: 24px;
    font-weight: bold; }
    .popup_refund_info .pop_head h3.title-2,
    .popup_apply_success .pop_head h3.title-2,
    .popup_mission .pop_head h3.title-2,
    .popup_marketing .pop_head h3.title-2,
    .popup_mission2 .pop_head h3.title-2,
    .popup_mission3 .pop_head h3.title-2,
    .popup_mission_final .pop_head h3.title-2 {
      color: #5cd99d; }
    .popup_refund_info .pop_head h3.title-3,
    .popup_apply_success .pop_head h3.title-3,
    .popup_mission .pop_head h3.title-3,
    .popup_marketing .pop_head h3.title-3,
    .popup_mission2 .pop_head h3.title-3,
    .popup_mission3 .pop_head h3.title-3,
    .popup_mission_final .pop_head h3.title-3 {
      color: #4acab9; }
  .popup_refund_info .pop_head h3 + p,
  .popup_apply_success .pop_head h3 + p,
  .popup_mission .pop_head h3 + p,
  .popup_marketing .pop_head h3 + p,
  .popup_mission2 .pop_head h3 + p,
  .popup_mission3 .pop_head h3 + p,
  .popup_mission_final .pop_head h3 + p {
    font-size: 16px;
    font-weight: bold;
    margin: 11px auto 15.5px; }
  .popup_refund_info .pop_head h3.title-2 + p,
  .popup_apply_success .pop_head h3.title-2 + p,
  .popup_mission .pop_head h3.title-2 + p,
  .popup_marketing .pop_head h3.title-2 + p,
  .popup_mission2 .pop_head h3.title-2 + p,
  .popup_mission3 .pop_head h3.title-2 + p,
  .popup_mission_final .pop_head h3.title-2 + p {
    color: #5cd99d; }
  .popup_refund_info .pop_head h3.title-3 + p,
  .popup_apply_success .pop_head h3.title-3 + p,
  .popup_mission .pop_head h3.title-3 + p,
  .popup_marketing .pop_head h3.title-3 + p,
  .popup_mission2 .pop_head h3.title-3 + p,
  .popup_mission3 .pop_head h3.title-3 + p,
  .popup_mission_final .pop_head h3.title-3 + p {
    color: #4acab9; }
  .popup_refund_info .pop_head p,
  .popup_apply_success .pop_head p,
  .popup_mission .pop_head p,
  .popup_marketing .pop_head p,
  .popup_mission2 .pop_head p,
  .popup_mission3 .pop_head p,
  .popup_mission_final .pop_head p {
    color: #000000;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.38;
    margin: 21px auto 18.5px;
    width: auto; }
  .popup_refund_info .pop_body,
  .popup_apply_success .pop_body,
  .popup_mission .pop_body,
  .popup_marketing .pop_body,
  .popup_mission2 .pop_body,
  .popup_mission3 .pop_body,
  .popup_mission_final .pop_body {
    padding: 0 40px 40px; }
    .popup_refund_info .pop_body.no_padding,
    .popup_apply_success .pop_body.no_padding,
    .popup_mission .pop_body.no_padding,
    .popup_marketing .pop_body.no_padding,
    .popup_mission2 .pop_body.no_padding,
    .popup_mission3 .pop_body.no_padding,
    .popup_mission_final .pop_body.no_padding {
      border-top: 1px solid #5cd99d;
      padding: 0; }
    .popup_refund_info .pop_body p.sub_content,
    .popup_apply_success .pop_body p.sub_content,
    .popup_mission .pop_body p.sub_content,
    .popup_marketing .pop_body p.sub_content,
    .popup_mission2 .pop_body p.sub_content,
    .popup_mission3 .pop_body p.sub_content,
    .popup_mission_final .pop_body p.sub_content {
      background: #f6f7f8;
      color: #9ba3a6;
      font-size: 12px;
      font-weight: normal;
      padding: 21px 0 22px;
      width: 100%; }
    .popup_refund_info .pop_body p.marketing_text,
    .popup_apply_success .pop_body p.marketing_text,
    .popup_mission .pop_body p.marketing_text,
    .popup_marketing .pop_body p.marketing_text,
    .popup_mission2 .pop_body p.marketing_text,
    .popup_mission3 .pop_body p.marketing_text,
    .popup_mission_final .pop_body p.marketing_text {
      background: #f6f7f8;
      color: #626569;
      font-size: 12.5px;
      font-weight: normal;
      line-height: 1.5;
      padding: 13.5px 8.5px 13px 11.5px;
      text-align: left; }
    .popup_refund_info .pop_body p.guide,
    .popup_apply_success .pop_body p.guide,
    .popup_mission .pop_body p.guide,
    .popup_marketing .pop_body p.guide,
    .popup_mission2 .pop_body p.guide,
    .popup_mission3 .pop_body p.guide,
    .popup_mission_final .pop_body p.guide {
      color: #9ba3a6;
      font-size: 16px;
      font-weight: normal;
      margin-top: 19px; }
    .popup_refund_info .pop_body .success-text,
    .popup_apply_success .pop_body .success-text,
    .popup_mission .pop_body .success-text,
    .popup_marketing .pop_body .success-text,
    .popup_mission2 .pop_body .success-text,
    .popup_mission3 .pop_body .success-text,
    .popup_mission_final .pop_body .success-text {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20.5px; }
    .popup_refund_info .pop_body .careful,
    .popup_apply_success .pop_body .careful,
    .popup_mission .pop_body .careful,
    .popup_marketing .pop_body .careful,
    .popup_mission2 .pop_body .careful,
    .popup_mission3 .pop_body .careful,
    .popup_mission_final .pop_body .careful {
      color: #9ba3a6;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.37;
      padding: 14.5px 20px 12.5px 16.5px;
      text-align: left; }
    .popup_refund_info .pop_body ul.study_list,
    .popup_apply_success .pop_body ul.study_list,
    .popup_mission .pop_body ul.study_list,
    .popup_marketing .pop_body ul.study_list,
    .popup_mission2 .pop_body ul.study_list,
    .popup_mission3 .pop_body ul.study_list,
    .popup_mission_final .pop_body ul.study_list {
      padding: 40px; }
      .popup_refund_info .pop_body ul.study_list li,
      .popup_apply_success .pop_body ul.study_list li,
      .popup_mission .pop_body ul.study_list li,
      .popup_marketing .pop_body ul.study_list li,
      .popup_mission2 .pop_body ul.study_list li,
      .popup_mission3 .pop_body ul.study_list li,
      .popup_mission_final .pop_body ul.study_list li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative; }
        .popup_refund_info .pop_body ul.study_list li + li,
        .popup_apply_success .pop_body ul.study_list li + li,
        .popup_mission .pop_body ul.study_list li + li,
        .popup_marketing .pop_body ul.study_list li + li,
        .popup_mission2 .pop_body ul.study_list li + li,
        .popup_mission3 .pop_body ul.study_list li + li,
        .popup_mission_final .pop_body ul.study_list li + li {
          border-top: 1px solid #f6f7f8;
          margin-top: 30px;
          padding-top: 30px; }
        .popup_refund_info .pop_body ul.study_list li.regular,
        .popup_apply_success .pop_body ul.study_list li.regular,
        .popup_mission .pop_body ul.study_list li.regular,
        .popup_marketing .pop_body ul.study_list li.regular,
        .popup_mission2 .pop_body ul.study_list li.regular,
        .popup_mission3 .pop_body ul.study_list li.regular,
        .popup_mission_final .pop_body ul.study_list li.regular {
          height: 90px; }
        .popup_refund_info .pop_body ul.study_list li .count,
        .popup_apply_success .pop_body ul.study_list li .count,
        .popup_mission .pop_body ul.study_list li .count,
        .popup_marketing .pop_body ul.study_list li .count,
        .popup_mission2 .pop_body ul.study_list li .count,
        .popup_mission3 .pop_body ul.study_list li .count,
        .popup_mission_final .pop_body ul.study_list li .count {
          color: #9ba3a6;
          font-size: 15.5px;
          font-weight: normal;
          position: absolute;
          right: 0;
          top: 40px; }
        .popup_refund_info .pop_body ul.study_list li .btn-submit,
        .popup_apply_success .pop_body ul.study_list li .btn-submit,
        .popup_mission .pop_body ul.study_list li .btn-submit,
        .popup_marketing .pop_body ul.study_list li .btn-submit,
        .popup_mission2 .pop_body ul.study_list li .btn-submit,
        .popup_mission3 .pop_body ul.study_list li .btn-submit,
        .popup_mission_final .pop_body ul.study_list li .btn-submit {
          background: #fff;
          border: 1px solid #e1e3e6;
          color: #e1e3e6;
          font-size: 16px;
          font-weight: bold;
          height: 45px;
          margin: 10px auto 0;
          position: relative;
          width: 153px; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.active,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.active,
          .popup_mission .pop_body ul.study_list li .btn-submit.active,
          .popup_marketing .pop_body ul.study_list li .btn-submit.active,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.active,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.active,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.active {
            border: solid 1px #13141a;
            color: #13141a; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.completed,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission .pop_body ul.study_list li .btn-submit.completed,
          .popup_marketing .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.completed,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.completed {
            border: none;
            color: #2bde73; }
          .popup_refund_info .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_apply_success .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_marketing .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission2 .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission3 .pop_body ul.study_list li .btn-submit.completed:after,
          .popup_mission_final .pop_body ul.study_list li .btn-submit.completed:after {
            background: url("https://cf.realclass.co.kr/qualson/images/c4e3b6af199258ce17ca126eec3a353f");
            background-size: 100% 100%;
            content: '';
            display: block;
            height: 10px;
            position: absolute;
            right: 29px;
            top: 15px;
            width: 12px; }
        .popup_refund_info .pop_body ul.study_list li .writingContents,
        .popup_apply_success .pop_body ul.study_list li .writingContents,
        .popup_mission .pop_body ul.study_list li .writingContents,
        .popup_marketing .pop_body ul.study_list li .writingContents,
        .popup_mission2 .pop_body ul.study_list li .writingContents,
        .popup_mission3 .pop_body ul.study_list li .writingContents,
        .popup_mission_final .pop_body ul.study_list li .writingContents {
          border: solid 1px #e1e3e6;
          box-sizing: border-box;
          font-size: 16px;
          padding: 10px 15px;
          width: 100%; }
        .popup_refund_info .pop_body ul.study_list li .content,
        .popup_apply_success .pop_body ul.study_list li .content,
        .popup_mission .pop_body ul.study_list li .content,
        .popup_marketing .pop_body ul.study_list li .content,
        .popup_mission2 .pop_body ul.study_list li .content,
        .popup_mission3 .pop_body ul.study_list li .content,
        .popup_mission_final .pop_body ul.study_list li .content {
          position: relative;
          text-align: left; }
          .popup_refund_info .pop_body ul.study_list li .content textarea,
          .popup_apply_success .pop_body ul.study_list li .content textarea,
          .popup_mission .pop_body ul.study_list li .content textarea,
          .popup_marketing .pop_body ul.study_list li .content textarea,
          .popup_mission2 .pop_body ul.study_list li .content textarea,
          .popup_mission3 .pop_body ul.study_list li .content textarea,
          .popup_mission_final .pop_body ul.study_list li .content textarea {
            -webkit-appearance: none;
            border: solid 1px #e1e3e6;
            border-radius: 0;
            color: #13141a;
            font-size: 16px;
            font-weight: normal;
            height: 173px;
            padding: 15px;
            resize: none;
            width: 100%; }
          .popup_refund_info .pop_body ul.study_list li .content.m20,
          .popup_apply_success .pop_body ul.study_list li .content.m20,
          .popup_mission .pop_body ul.study_list li .content.m20,
          .popup_marketing .pop_body ul.study_list li .content.m20,
          .popup_mission2 .pop_body ul.study_list li .content.m20,
          .popup_mission3 .pop_body ul.study_list li .content.m20,
          .popup_mission_final .pop_body ul.study_list li .content.m20 {
            margin: 0 55px; }
          .popup_refund_info .pop_body ul.study_list li .content input[type='file'],
          .popup_apply_success .pop_body ul.study_list li .content input[type='file'],
          .popup_mission .pop_body ul.study_list li .content input[type='file'],
          .popup_marketing .pop_body ul.study_list li .content input[type='file'],
          .popup_mission2 .pop_body ul.study_list li .content input[type='file'],
          .popup_mission3 .pop_body ul.study_list li .content input[type='file'],
          .popup_mission_final .pop_body ul.study_list li .content input[type='file'] {
            cursor: pointer;
            height: 181px;
            left: 0px;
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 225px; }
          .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel,
          .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel {
            padding-left: 32px; }
            .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level,
            .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level {
              background: #fff;
              border: 1px solid #e1e3e6;
              border-radius: 17px;
              color: #e1e3e6;
              cursor: pointer;
              display: inline-block;
              font-size: 17px;
              font-weight: 500;
              height: 35px;
              line-height: 35px;
              text-align: center;
              white-space: nowrap;
              width: 93px; }
              .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on,
              .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level.on {
                background: #13141a;
                border: none;
                color: #fff; }
              .popup_refund_info .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_apply_success .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_marketing .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission2 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission3 .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2),
              .popup_mission_final .pop_body ul.study_list li .content .wrapCheckLevel .btn-level:nth-child(2) {
                margin: 0 6px; }
          .popup_refund_info .pop_body ul.study_list li .content .btn-upload,
          .popup_apply_success .pop_body ul.study_list li .content .btn-upload,
          .popup_mission .pop_body ul.study_list li .content .btn-upload,
          .popup_marketing .pop_body ul.study_list li .content .btn-upload,
          .popup_mission2 .pop_body ul.study_list li .content .btn-upload,
          .popup_mission3 .pop_body ul.study_list li .content .btn-upload,
          .popup_mission_final .pop_body ul.study_list li .content .btn-upload {
            background: url("https://cf.realclass.co.kr/qualson/images/db3a0ba73b721b02dea2f6a8bd0eb0ca");
            background-size: 100% 100%;
            border: none;
            height: 181px;
            width: 225px; }
            .popup_refund_info .pop_body ul.study_list li .content .btn-upload.off,
            .popup_apply_success .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission .pop_body ul.study_list li .content .btn-upload.off,
            .popup_marketing .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission2 .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission3 .pop_body ul.study_list li .content .btn-upload.off,
            .popup_mission_final .pop_body ul.study_list li .content .btn-upload.off {
              display: none; }
          .popup_refund_info .pop_body ul.study_list li .content div.pic,
          .popup_apply_success .pop_body ul.study_list li .content div.pic,
          .popup_mission .pop_body ul.study_list li .content div.pic,
          .popup_marketing .pop_body ul.study_list li .content div.pic,
          .popup_mission2 .pop_body ul.study_list li .content div.pic,
          .popup_mission3 .pop_body ul.study_list li .content div.pic,
          .popup_mission_final .pop_body ul.study_list li .content div.pic {
            background: url("https://cf.realclass.co.kr/qualson/images/6b4ef9547d72a61d95b39c1c191be7b2");
            background-size: 100% 100%;
            display: none;
            height: 181px;
            position: relative;
            width: 225px; }
            .popup_refund_info .pop_body ul.study_list li .content div.pic.on,
            .popup_apply_success .pop_body ul.study_list li .content div.pic.on,
            .popup_mission .pop_body ul.study_list li .content div.pic.on,
            .popup_marketing .pop_body ul.study_list li .content div.pic.on,
            .popup_mission2 .pop_body ul.study_list li .content div.pic.on,
            .popup_mission3 .pop_body ul.study_list li .content div.pic.on,
            .popup_mission_final .pop_body ul.study_list li .content div.pic.on {
              display: block; }
            .popup_refund_info .pop_body ul.study_list li .content div.pic.on:after,
            .popup_apply_success .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission .pop_body ul.study_list li .content div.pic.on:after,
            .popup_marketing .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission2 .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission3 .pop_body ul.study_list li .content div.pic.on:after,
            .popup_mission_final .pop_body ul.study_list li .content div.pic.on:after {
              background: url("https://cf.realclass.co.kr/qualson/images/7a5f04f260d909e238588e064dd1c6b4");
              background-size: 100% 100%;
              bottom: -10px;
              content: '';
              display: block;
              height: 30px;
              position: absolute;
              right: -10px;
              width: 30px; }
        .popup_refund_info .pop_body ul.study_list li .wrapCheckStar,
        .popup_apply_success .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission .pop_body ul.study_list li .wrapCheckStar,
        .popup_marketing .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission2 .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission3 .pop_body ul.study_list li .wrapCheckStar,
        .popup_mission_final .pop_body ul.study_list li .wrapCheckStar {
          padding-left: 32px; }
          .popup_refund_info .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_apply_success .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_marketing .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission2 .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission3 .pop_body ul.study_list li .wrapCheckStar .star,
          .popup_mission_final .pop_body ul.study_list li .wrapCheckStar .star {
            background: url("https://cf.realclass.co.kr/qualson/images/954528e062512e74608ab725c9ceaf0c");
            background-size: 100% 100%;
            cursor: pointer;
            display: inline-block;
            height: 39px;
            width: 42px; }
            .popup_refund_info .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_apply_success .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_marketing .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission2 .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission3 .pop_body ul.study_list li .wrapCheckStar .star.on,
            .popup_mission_final .pop_body ul.study_list li .wrapCheckStar .star.on {
              background: url("https://cf.realclass.co.kr/qualson/images/b13195f80abb79e378f80dfc4ca497e8");
              background-size: 100% 100%; }
        .popup_refund_info .pop_body ul.study_list li .head,
        .popup_apply_success .pop_body ul.study_list li .head,
        .popup_mission .pop_body ul.study_list li .head,
        .popup_marketing .pop_body ul.study_list li .head,
        .popup_mission2 .pop_body ul.study_list li .head,
        .popup_mission3 .pop_body ul.study_list li .head,
        .popup_mission_final .pop_body ul.study_list li .head {
          align-items: center;
          display: flex;
          margin-bottom: 13px; }
        .popup_refund_info .pop_body ul.study_list li .icoIndex,
        .popup_apply_success .pop_body ul.study_list li .icoIndex,
        .popup_mission .pop_body ul.study_list li .icoIndex,
        .popup_marketing .pop_body ul.study_list li .icoIndex,
        .popup_mission2 .pop_body ul.study_list li .icoIndex,
        .popup_mission3 .pop_body ul.study_list li .icoIndex,
        .popup_mission_final .pop_body ul.study_list li .icoIndex {
          background-color: #5cd99d;
          border-radius: 10px;
          color: #fff;
          font-style: normal;
          height: 22px;
          line-height: 22px;
          margin-right: 10px;
          width: 22px; }
        .popup_refund_info .pop_body ul.study_list li .ico-check,
        .popup_apply_success .pop_body ul.study_list li .ico-check,
        .popup_mission .pop_body ul.study_list li .ico-check,
        .popup_marketing .pop_body ul.study_list li .ico-check,
        .popup_mission2 .pop_body ul.study_list li .ico-check,
        .popup_mission3 .pop_body ul.study_list li .ico-check,
        .popup_mission_final .pop_body ul.study_list li .ico-check {
          background: url("https://cf.realclass.co.kr/qualson/images/49ad8d1ed98ce4895f0d3d7a07f7d324");
          background-size: 100% 100%;
          display: inline-block;
          height: 22px;
          margin-right: 10px;
          width: 22px; }
          .popup_refund_info .pop_body ul.study_list li .ico-check.on,
          .popup_apply_success .pop_body ul.study_list li .ico-check.on,
          .popup_mission .pop_body ul.study_list li .ico-check.on,
          .popup_marketing .pop_body ul.study_list li .ico-check.on,
          .popup_mission2 .pop_body ul.study_list li .ico-check.on,
          .popup_mission3 .pop_body ul.study_list li .ico-check.on,
          .popup_mission_final .pop_body ul.study_list li .ico-check.on {
            background: url("https://cf.realclass.co.kr/qualson/images/18de4938438ddd21d63dde249f5ce534");
            background-size: 100% 100%; }
        .popup_refund_info .pop_body ul.study_list li .btn-group,
        .popup_apply_success .pop_body ul.study_list li .btn-group,
        .popup_mission .pop_body ul.study_list li .btn-group,
        .popup_marketing .pop_body ul.study_list li .btn-group,
        .popup_mission2 .pop_body ul.study_list li .btn-group,
        .popup_mission3 .pop_body ul.study_list li .btn-group,
        .popup_mission_final .pop_body ul.study_list li .btn-group {
          align-items: center;
          display: flex;
          margin-left: 53.5px;
          text-align: left; }
          .popup_refund_info .pop_body ul.study_list li .btn-group span.btn,
          .popup_apply_success .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission .pop_body ul.study_list li .btn-group span.btn,
          .popup_marketing .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission2 .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission3 .pop_body ul.study_list li .btn-group span.btn,
          .popup_mission_final .pop_body ul.study_list li .btn-group span.btn {
            display: inline-block;
            height: 31px;
            margin-right: 7.5px;
            width: 59px; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_apply_success .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_marketing .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission2 .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission3 .pop_body ul.study_list li .btn-group .btn-voice,
          .popup_mission_final .pop_body ul.study_list li .btn-group .btn-voice {
            background: url("https://cf.realclass.co.kr/qualson/images/2941651500c9ff8b61d7a31441f4b75d");
            background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-voice.on,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-voice.on {
              background: url("https://cf.realclass.co.kr/qualson/images/e6dc6bfcbc8b82f0eb60ef1761d9975c");
              background-size: 100% 100%; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play,
          .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission .pop_body ul.study_list li .btn-group .btn-play,
          .popup_marketing .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play,
          .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play {
            background: url("https://cf.realclass.co.kr/qualson/images/cb177a4f712c54804dc33f5aca949e27");
            background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play.on,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play.on {
              background: url("https://cf.realclass.co.kr/qualson/images/6906b6c8cc4dc3d666d4b4c5ce73bf00");
              background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_apply_success .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_marketing .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission2 .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission3 .pop_body ul.study_list li .btn-group .btn-play.stop,
            .popup_mission_final .pop_body ul.study_list li .btn-group .btn-play.stop {
              background: url("https://cf.realclass.co.kr/qualson/images/b15c2e4585b372c13a1edf7fcc656faa");
              background-size: 100% 100%; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .time,
          .popup_apply_success .pop_body ul.study_list li .btn-group .time,
          .popup_mission .pop_body ul.study_list li .btn-group .time,
          .popup_marketing .pop_body ul.study_list li .btn-group .time,
          .popup_mission2 .pop_body ul.study_list li .btn-group .time,
          .popup_mission3 .pop_body ul.study_list li .btn-group .time,
          .popup_mission_final .pop_body ul.study_list li .btn-group .time {
            color: #9ba3a6;
            font-size: 17.5px;
            font-weight: normal;
            margin-left: 5px; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .time.record,
            .popup_apply_success .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission .pop_body ul.study_list li .btn-group .time.record,
            .popup_marketing .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission2 .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission3 .pop_body ul.study_list li .btn-group .time.record,
            .popup_mission_final .pop_body ul.study_list li .btn-group .time.record {
              color: #ff414d; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .time.play,
            .popup_apply_success .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission .pop_body ul.study_list li .btn-group .time.play,
            .popup_marketing .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission2 .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission3 .pop_body ul.study_list li .btn-group .time.play,
            .popup_mission_final .pop_body ul.study_list li .btn-group .time.play {
              color: #2bde73; }
          .popup_refund_info .pop_body ul.study_list li .btn-group .ico,
          .popup_apply_success .pop_body ul.study_list li .btn-group .ico,
          .popup_mission .pop_body ul.study_list li .btn-group .ico,
          .popup_marketing .pop_body ul.study_list li .btn-group .ico,
          .popup_mission2 .pop_body ul.study_list li .btn-group .ico,
          .popup_mission3 .pop_body ul.study_list li .btn-group .ico,
          .popup_mission_final .pop_body ul.study_list li .btn-group .ico {
            display: inline-block;
            height: 13px;
            margin-left: 9px;
            width: 15px; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .ico.play,
            .popup_apply_success .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission .pop_body ul.study_list li .btn-group .ico.play,
            .popup_marketing .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission2 .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission3 .pop_body ul.study_list li .btn-group .ico.play,
            .popup_mission_final .pop_body ul.study_list li .btn-group .ico.play {
              background: url("https://cf.realclass.co.kr/qualson/images/bd33639e767b311a0d4bfe2a657379b0");
              background-size: 100% 100%; }
            .popup_refund_info .pop_body ul.study_list li .btn-group .ico.record,
            .popup_apply_success .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission .pop_body ul.study_list li .btn-group .ico.record,
            .popup_marketing .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission2 .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission3 .pop_body ul.study_list li .btn-group .ico.record,
            .popup_mission_final .pop_body ul.study_list li .btn-group .ico.record {
              background: url("https://cf.realclass.co.kr/qualson/images/72ce4715dbafd36494b5187ed64a1885");
              background-size: 100% 100%; }
      .popup_refund_info .pop_body ul.study_list.type2 li,
      .popup_apply_success .pop_body ul.study_list.type2 li,
      .popup_mission .pop_body ul.study_list.type2 li,
      .popup_marketing .pop_body ul.study_list.type2 li,
      .popup_mission2 .pop_body ul.study_list.type2 li,
      .popup_mission3 .pop_body ul.study_list.type2 li,
      .popup_mission_final .pop_body ul.study_list.type2 li {
        height: auto; }
    .popup_refund_info .pop_body ul.info_list li,
    .popup_apply_success .pop_body ul.info_list li,
    .popup_mission .pop_body ul.info_list li,
    .popup_marketing .pop_body ul.info_list li,
    .popup_mission2 .pop_body ul.info_list li,
    .popup_mission3 .pop_body ul.info_list li,
    .popup_mission_final .pop_body ul.info_list li {
      background: #f6f7f8;
      display: flex;
      padding: 23px 20px 21px;
      width: 317px; }
      .popup_refund_info .pop_body ul.info_list li + li,
      .popup_apply_success .pop_body ul.info_list li + li,
      .popup_mission .pop_body ul.info_list li + li,
      .popup_marketing .pop_body ul.info_list li + li,
      .popup_mission2 .pop_body ul.info_list li + li,
      .popup_mission3 .pop_body ul.info_list li + li,
      .popup_mission_final .pop_body ul.info_list li + li {
        margin-top: 10px; }
      .popup_refund_info .pop_body ul.info_list li p,
      .popup_apply_success .pop_body ul.info_list li p,
      .popup_mission .pop_body ul.info_list li p,
      .popup_marketing .pop_body ul.info_list li p,
      .popup_mission2 .pop_body ul.info_list li p,
      .popup_mission3 .pop_body ul.info_list li p,
      .popup_mission_final .pop_body ul.info_list li p {
        margin-left: 8.5px;
        text-align: left; }
    .popup_refund_info .pop_body ul.mission_list,
    .popup_apply_success .pop_body ul.mission_list,
    .popup_mission .pop_body ul.mission_list,
    .popup_marketing .pop_body ul.mission_list,
    .popup_mission2 .pop_body ul.mission_list,
    .popup_mission3 .pop_body ul.mission_list,
    .popup_mission_final .pop_body ul.mission_list {
      margin-top: 22.5px;
      padding-left: 25px; }
      .popup_refund_info .pop_body ul.mission_list li,
      .popup_apply_success .pop_body ul.mission_list li,
      .popup_mission .pop_body ul.mission_list li,
      .popup_marketing .pop_body ul.mission_list li,
      .popup_mission2 .pop_body ul.mission_list li,
      .popup_mission3 .pop_body ul.mission_list li,
      .popup_mission_final .pop_body ul.mission_list li {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        list-style-type: disc;
        text-align: left; }
        .popup_refund_info .pop_body ul.mission_list li + li,
        .popup_apply_success .pop_body ul.mission_list li + li,
        .popup_mission .pop_body ul.mission_list li + li,
        .popup_marketing .pop_body ul.mission_list li + li,
        .popup_mission2 .pop_body ul.mission_list li + li,
        .popup_mission3 .pop_body ul.mission_list li + li,
        .popup_mission_final .pop_body ul.mission_list li + li {
          margin-top: 16px; }
  .popup_refund_info .btn_group button,
  .popup_apply_success .btn_group button,
  .popup_mission .btn_group button,
  .popup_marketing .btn_group button,
  .popup_mission2 .btn_group button,
  .popup_mission3 .btn_group button,
  .popup_mission_final .btn_group button {
    background: #000 !important;
    border: none !important;
    color: #fff !important; }

.popup_mission2 .pop_inner {
  height: 550px;
  overflow: hidden;
  overflow-y: auto; }

.popup_mission3 .pop_inner,
.popup_mission_final .pop_inner {
  height: 550px;
  overflow: hidden;
  overflow-y: auto; }
  .popup_mission3 .pop_inner .pic-swiper,
  .popup_mission_final .pop_inner .pic-swiper {
    position: relative; }
  .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box,
  .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 42px;
    top: -38px; }
    .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button,
    .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      cursor: pointer;
      display: inline-block;
      height: 22px;
      width: 22px; }
      .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-prev,
      .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-prev {
        background-image: url(https://cf.realclass.co.kr/qualson/images/cc92137cc3a1b1e954b3733e67d4a824); }
      .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-next,
      .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button.swiper-button-next {
        background-image: url(https://cf.realclass.co.kr/qualson/images/56bb9ac4b8d87a65770a727f7832df83); }
      .popup_mission3 .pop_inner .pic-swiper-container .swiper-button-box .swiper-button + .swiper-button,
      .popup_mission_final .pop_inner .pic-swiper-container .swiper-button-box .swiper-button + .swiper-button {
        margin-left: 20px; }

.pic-swiper {
  padding: 0 21px; }

.pic-swiper-container.swiper-container {
  height: 100%;
  margin: 0;
  padding: 0 25px;
  width: 100%; }

.pic-swiper-container .swiper-wrapper {
  display: flex; }
  .pic-swiper-container .swiper-wrapper .swiper-slide + .swiper-slide {
    margin-left: 18px; }
  .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item {
    position: relative;
    width: 225px; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item.rect {
      width: 180px; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .sub-text {
      color: #9ba3a6;
      font-size: 12.5px;
      font-weight: 500;
      margin-left: 2px;
      text-align: left; }
    .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content {
      position: relative;
      text-align: left; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content textarea {
        -webkit-appearance: none;
        border: solid 1px #e1e3e6;
        border-radius: 0;
        color: #13141a;
        font-size: 12.5px;
        font-weight: normal;
        height: 86.5px;
        margin-top: 3px;
        padding: 12px 4.5px 8px 13px;
        resize: none;
        width: 254.5px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content.m20 {
        margin-left: 20px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content input[type='file'] {
        cursor: pointer;
        height: 181px;
        left: 0px;
        opacity: 0;
        position: absolute;
        top: 0px;
        width: 225px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level {
        background: #fff;
        border: 1px solid #e1e3e6;
        border-radius: 10.6px;
        color: #e1e3e6;
        display: inline-block;
        font-size: 12.5px;
        font-weight: 500;
        height: 21.5px;
        line-height: 21.5px;
        text-align: center;
        white-space: nowrap;
        width: 60px; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level.on {
          background: #13141a;
          border: none;
          color: #fff; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-level:nth-child(2) {
          margin: 0 6px; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-upload {
        background: url("https://cf.realclass.co.kr/qualson/images/460740734450b2027a475678b3f8b449");
        background-size: 100% 100%;
        border: none;
        height: 181px;
        width: 225px; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content .btn-upload.off {
          display: none; }
      .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic {
        background-size: 100% 100%;
        display: none;
        height: 181px;
        position: relative;
        width: 225px;
        pointer-events: none; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic.on {
          display: block; }
        .pic-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-item .swiper-content div.pic.on:after {
          background: url("https://cf.realclass.co.kr/qualson/images/607e5b9dcda5d291ae21ceba3056ddd2");
          background-size: 100% 100%;
          bottom: -10px;
          content: '';
          display: block;
          height: 30px;
          position: absolute;
          right: -10px;
          width: 30px; }

body {
  font-size: 12.8px; }

.inner {
  width: 1024px; }

.full_banner {
  background-color: #f1efec; }
  .full_banner .inner {
    min-width: 1024px;
    max-width: 1440px;
    width: 100%;
    overflow: hidden; }
  .full_banner .jw_player {
    width: 1536px;
    height: 630.4px;
    overflow: hidden;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
    .full_banner .jw_player .jwplayer {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: transparent; }
      .full_banner .jw_player .jwplayer .jw-aspect {
        padding-top: 41% !important; }
  .full_banner .contents ul {
    font-size: 0;
    white-space: nowrap;
    position: relative;
    left: 0;
    -webkit-transition: left 1.2s;
    -moz-transition: left 1.2s;
    -ms-transition: left 1.2s;
    -o-transition: left 1.2s;
    transition: left 1.2s; }
  .full_banner .contents li {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 630.4px;
    display: inline-block; }
  .full_banner .contents .bg_area {
    height: 100%; }
    .full_banner .contents .bg_area img {
      display: block;
      position: relative;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%); }
  .full_banner .contents .float_area {
    width: 100%;
    height: 100%;
    padding: 0 93.5px 0 93.5px;
    position: absolute;
    top: 0;
    left: 0; }
    .full_banner .contents .float_area .inner_area {
      max-width: 1024px;
      margin: 0 auto; }
  .full_banner .contents .left_section {
    padding-top: 254.4px; }
    .full_banner .contents .left_section h4 {
      font-size: 35px; }
      .full_banner .contents .left_section h4 img {
        width: 275.2px;
        height: auto;
        display: block; }
    .full_banner .contents .left_section p {
      margin-top: 25.6px;
      font-size: 19.2px; }
    .full_banner .contents .left_section button,
    .full_banner .contents .left_section a {
      width: 232px;
      margin-top: 28px;
      position: relative;
      z-index: 10;
      cursor: pointer; }
  .full_banner .contents .right_section {
    padding-top: 229.6px;
    text-align: right; }
    .full_banner .contents .right_section .img_area {
      width: 280px;
      height: 198px;
      display: inline-block;
      border: 5px solid #fff; }
      .full_banner .contents .right_section .img_area img {
        display: block;
        width: 100%;
        height: auto; }
    .full_banner .contents .right_section .txt_area {
      margin-top: 13px;
      font-size: 14px;
      text-align: right; }
  .full_banner .pagination_group {
    width: 1024px;
    position: absolute;
    bottom: 48px;
    left: 50%;
    font-size: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1; }
    .full_banner .pagination_group .pagination {
      position: absolute;
      left: 0;
      bottom: 0; }
    .full_banner .pagination_group button {
      width: 7px;
      height: 7px;
      margin: 4px;
      padding: 0;
      background-color: #000;
      border: none; }
      .full_banner .pagination_group button.on {
        background-color: #2bde73; }
  .full_banner .controller button {
    width: 35%;
    height: 100%;
    padding: 0 14px;
    position: absolute;
    top: 0;
    z-index: 1; }
  .full_banner .controller .btn_prev {
    left: 0; }
  .full_banner .controller .btn_next {
    right: 0; }

.full_banner .controller .btn_prev:hover,
.real_video .controller .btn_prev:hover,
.real_teacher .controller .btn_prev:hover {
  cursor: url("https://cf.realclass.co.kr/qualson/images/home_cursor_left_lg.png"), pointer; }

.full_banner .controller .btn_next:hover,
.real_video .controller .btn_next:hover,
.real_teacher .controller .btn_next:hover {
  cursor: url("https://cf.realclass.co.kr/qualson/images/home_cursor_right_lg.png"), pointer; }

.main {
  min-width: 1280px;
  width: 100%;
  overflow: hidden; }
  .main h2,
  .main h3 {
    font-weight: 400; }
  .main h2 {
    font-size: 24px; }
    .main h2.title_sm {
      font-size: 20.8px; }
  .main .left_section {
    float: left; }
  .main .right_section {
    float: right; }

.real_customers {
  padding: 62px 0 21px;
  border-bottom: 2px solid #5cd99d;
  text-align: center; }
  .real_customers .inner {
    width: 1440px; }
  .real_customers.open .img_more {
    display: block; }
  .real_customers.open h3 {
    margin-bottom: 30px; }
  .real_customers h3 {
    margin-bottom: 10px;
    color: #767e81;
    font-size: 16px;
    line-height: 1.3; }
  .real_customers p {
    margin-bottom: 33px;
    color: #a3acaf;
    font-size: 14px;
    line-height: 1.4; }
  .real_customers .btn_more {
    width: 100%;
    margin-top: 5px;
    padding: 14px 0;
    font-size: 12px;
    color: #2bde73;
    border: none;
    background-color: transparent; }
    .real_customers .btn_more:after {
      content: '';
      margin-left: 4px;
      display: inline-block;
      vertical-align: middle;
      background-image: url(../images/sprite/common.png);
      background-position: -766px -1035px;
      width: 13px;
      height: 9px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .real_customers .btn_more:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
  .real_customers .img_more {
    display: none; }
  .real_customers .slide_area {
    overflow: hidden; }
  .real_customers .img_group {
    position: relative;
    top: 0;
    left: 0;
    -webkit-animation: slide 125s linear infinite;
    -moz-animation: slide 125s linear infinite;
    animation: slide 125s linear infinite; }
  .real_customers .img_group,
  .real_customers .img_more {
    width: 100%;
    white-space: nowrap; }
    .real_customers .img_group img,
    .real_customers .img_more img {
      width: auto;
      height: 116px;
      margin: 0 auto;
      display: inline-block;
      vertical-align: top; }

@-webkit-keyframes slide {
  0% {
    left: 0; }
  100% {
    left: -8409px; } }

@-moz-keyframes slide {
  0% {
    left: 0; }
  100% {
    left: -8409px; } }

@keyframes slide {
  0% {
    left: 0; }
  100% {
    left: -8409px; } }

.real_english h3,
.real_easy h3 {
  color: #2bde73; }

.real_english li,
.real_easy li {
  width: 220px;
  position: relative;
  display: inline-block; }
  .real_english li span,
  .real_easy li span {
    margin: 0 auto;
    position: relative;
    display: block; }
    .real_english li span img,
    .real_easy li span img {
      position: absolute; }
  .real_english li p,
  .real_easy li p {
    color: #9f9f9f;
    line-height: 1.4; }

.real_english,
.real_easy,
.real_question {
  text-align: center; }
  .real_english h2,
  .real_easy h2,
  .real_question h2 {
    color: #fff;
    line-height: 1.4; }
    .real_english h2 strong,
    .real_easy h2 strong,
    .real_question h2 strong {
      color: #2bde73;
      font-weight: 400; }

.real_english {
  padding: 90.1px 0 110.5px 0; }
  .real_english h2 {
    color: #000; }
    .real_english h2 strong {
      margin-top: 10px;
      display: block;
      font-weight: 600;
      font-size: 23px; }
  .real_english ul {
    margin-top: 52.8px; }
  .real_english li {
    padding: 0 14px; }
    .real_english li + li:after {
      content: '';
      position: absolute;
      top: 64px;
      left: -14px;
      background-image: url(../images/sprite/common.png);
      background-position: -1433px -135px;
      width: 26px;
      height: 27px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .real_english li + li:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .real_english li span {
      width: 151px;
      height: 144px;
      border: 7px solid #bcc2c9; }
      .real_english li span i {
        position: absolute;
        top: 40px;
        left: -9px;
        background-color: #fff;
        font-size: 0; }
        .real_english li span i:before {
          content: '';
          width: 140px;
          height: 64px;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: auto 64px; }
    .real_english li p {
      color: #626569;
      font-size: 15px;
      margin-top: 24px; }
  .real_english .plus_video i:before {
    background-image: url("https://cf.realclass.co.kr/qualson/images/txt_main_real_video.png"); }
  .real_english .plus_teacher i:before {
    background-image: url("https://cf.realclass.co.kr/qualson/images/txt_main_real_teacher.png"); }
  .real_english .plus_training i:before {
    background-image: url("https://cf.realclass.co.kr/qualson/images/txt_main_real_training.png"); }

.real_video {
  overflow: hidden; }
  .real_video .top_section .inner,
  .real_video .bottom_section .inner {
    padding: 0 93.5px 0 93.5px; }
  .real_video .top_section {
    height: 464.8px;
    padding: 102px 0 0 0;
    position: relative;
    background-color: #3ad478; }
  .real_video .bottom_section {
    height: 464.8px;
    background-color: #fff; }
    .real_video .bottom_section .inner {
      height: 100%; }
  .real_video .pagination,
  .real_video .controller button,
  .real_video .contract_info_group {
    z-index: 1; }
  .real_video .slides {
    width: 100%;
    min-height: 477.6px;
    position: absolute;
    left: 0;
    top: -216px;
    padding: 0 187px 0 220px; }
    .real_video .slides li {
      display: none; }
      .real_video .slides li.show {
        display: block; }
    .real_video .slides .media_area,
    .real_video .slides .review_area {
      width: 100%; }
    .real_video .slides .media_group {
      position: relative;
      width: 496px; }
    .real_video .slides .media_area {
      position: relative;
      padding-top: 56%;
      border: 2px solid #2bde73;
      z-index: 1; }
      .real_video .slides .media_area .img_area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #fff; }
        .real_video .slides .media_area .img_area img {
          margin-top: -3px;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: auto;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); }
      .real_video .slides .media_area .jw_player {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
      .real_video .slides .media_area video {
        width: 100%;
        height: auto;
        display: block; }
      .real_video .slides .media_area.playing a {
        background: transparent; }
      .real_video .slides .media_area a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer; }
      .real_video .slides .media_area p {
        width: 193.6px;
        height: 84.8px;
        padding-top: 24px;
        position: absolute;
        top: -27px;
        left: -23px;
        background-color: rgba(72, 242, 140, 0.9);
        text-align: center;
        box-shadow: 6px 5px 0 rgba(0, 0, 0, 0.15); }
        .real_video .slides .media_area p span {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          line-height: 1.3;
          color: #fff;
          font-size: 24px;
          white-space: nowrap; }
    .real_video .slides .review_area {
      position: relative;
      margin: -93.5px 0 0 93.5px;
      padding: 124.1px 42.5px 0 42.5px;
      background-color: rgba(235, 235, 235, 0.9);
      border: 1px solid #2bde73; }
    .real_video .slides .bg_area {
      position: absolute; }
      .real_video .slides .bg_area img {
        height: auto; }
    .real_video .slides .slide1 .bg_area {
      right: -92.8px;
      bottom: -134.4px; }
      .real_video .slides .slide1 .bg_area img {
        width: 423.2px; }
    .real_video .slides .slide2 .bg_area {
      right: -197.6px;
      bottom: -94.4px; }
      .real_video .slides .slide2 .bg_area img {
        width: 521.6px; }
    .real_video .slides .slide3 .bg_area {
      right: -102.4px;
      bottom: -107.2px; }
      .real_video .slides .slide3 .bg_area img {
        width: 1096px; }
  .real_video .rel_content {
    position: relative; }
  .real_video .pagination {
    position: absolute;
    bottom: 140px;
    left: 214px; }
    .real_video .pagination button {
      display: block;
      border: none;
      background-color: transparent;
      color: #687980;
      font-weight: 600; }
      .real_video .pagination button.on {
        color: #2bde73; }
        .real_video .pagination button.on:after {
          content: '';
          width: 8px;
          height: 8px;
          margin-left: 6px;
          display: inline-block;
          border-radius: 50%;
          vertical-align: middle;
          background-color: #2bde73; }
      .real_video .pagination button + button {
        margin-top: 10px; }
  .real_video .controller button {
    width: 410px;
    height: 100%;
    position: absolute;
    top: 0; }
  .real_video .controller .btn_prev {
    left: -210px; }
  .real_video .controller .btn_next {
    right: -210px; }
  .real_video .review_box {
    position: relative; }
    .real_video .review_box:before {
      left: -18px;
      top: -24px; }
    .real_video .review_box .txt_group {
      padding-bottom: 23px;
      line-height: 1.4; }
      .real_video .review_box .txt_group strong {
        margin-bottom: 12px;
        display: block; }
    .real_video .review_box .img_group {
      padding: 13px 0 20px;
      border-top: 1px solid #2bde73; }
      .real_video .review_box .img_group strong {
        font-size: 14px;
        font-weight: 400; }
      .real_video .review_box .img_group span + strong {
        margin-left: 20px; }
      .real_video .review_box .img_group strong + strong:before {
        content: '|';
        margin: 0 12px;
        display: inline-block;
        vertical-align: middle; }
  .real_video .slope:before {
    background-color: #fff; }
  .real_video .contract_info_group {
    position: absolute;
    right: 0;
    bottom: 56px; }
    .real_video .contract_info_group p {
      padding: 0 32px;
      display: inline-block; }
    .real_video .contract_info_group button {
      min-width: 126px;
      width: 126px;
      height: 43px;
      font-size: 14px;
      line-height: 39px; }
    .real_video .contract_info_group .icon_turner:before {
      content: '';
      display: block;
      margin: 0 auto 15px;
      background-image: url(../images/sprite/common.png);
      background-position: -1106px -941px;
      width: 120px;
      height: 58px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .real_video .contract_info_group .icon_turner:before {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
  .real_video .section_title {
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -498px -1066px;
    width: 120px;
    height: 39px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .real_video .section_title {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .real_video h2 {
    margin-top: 22.4px; }

.real_teacher {
  padding: 95.2px 0 0 0;
  background-color: #ecf0f2; }
  .real_teacher .inner {
    padding-left: 88px; }
  .real_teacher .section_title {
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -327px -1066px;
    width: 156px;
    height: 39px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .real_teacher .section_title {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .real_teacher h2 {
    margin-top: 20px; }
  .real_teacher .slides {
    margin-top: 90.4px;
    padding-right: 88px; }
    .real_teacher .slides .contents {
      min-height: 421.6px; }
  .real_teacher .pagination_group {
    position: absolute;
    top: -19.2px;
    right: 16px;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%); }
    .real_teacher .pagination_group .scroll_area {
      width: 505.6px;
      position: relative;
      overflow: hidden; }
    .real_teacher .pagination_group .bg_group {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0;
      white-space: nowrap; }
      .real_teacher .pagination_group .bg_group span {
        width: 126.4px;
        height: 174.4px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% auto; }
      .real_teacher .pagination_group .bg_group .slide1 {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_1_off.png"); }
        .real_teacher .pagination_group .bg_group .slide1.on {
          background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_1_on.png"); }
      .real_teacher .pagination_group .bg_group .slide2 {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_2_off.png"); }
        .real_teacher .pagination_group .bg_group .slide2.on {
          background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_2_on.png"); }
      .real_teacher .pagination_group .bg_group .slide3 {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_3_off.png"); }
        .real_teacher .pagination_group .bg_group .slide3.on {
          background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_3_on.png"); }
      .real_teacher .pagination_group .bg_group .slide4 {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_4_off.png"); }
        .real_teacher .pagination_group .bg_group .slide4.on {
          background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_bg_4_on.png"); }
  .real_teacher .controller_pagination .btn_prev,
  .real_teacher .controller_pagination .btn_next {
    height: 120px;
    position: absolute;
    top: 0;
    border: none;
    background-color: transparent;
    display: none; }
    .real_teacher .controller_pagination .btn_prev.on,
    .real_teacher .controller_pagination .btn_next.on {
      display: block; }
    .real_teacher .controller_pagination .btn_prev span,
    .real_teacher .controller_pagination .btn_next span {
      display: inline-block;
      background-image: url(../images/sprite/common.png);
      background-position: -1078px -520px;
      width: 12px;
      height: 16px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .real_teacher .controller_pagination .btn_prev span,
        .real_teacher .controller_pagination .btn_next span {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
  .real_teacher .controller_pagination .btn_prev {
    left: -35px; }
  .real_teacher .controller_pagination .btn_next {
    right: -20px; }
    .real_teacher .controller_pagination .btn_next span {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); }
  .real_teacher .pagination {
    position: relative;
    font-size: 0;
    white-space: nowrap; }
    .real_teacher .pagination button {
      padding: 0;
      position: relative;
      background-color: transparent;
      border: none; }
      .real_teacher .pagination button .img_area {
        width: 126.4px;
        height: 174.4px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% auto; }
        .real_teacher .pagination button .img_area img {
          display: block; }
      .real_teacher .pagination button .txt_area {
        padding-top: 16px;
        position: absolute;
        top: 0;
        font-size: 12px;
        color: #474747; }
      .real_teacher .pagination button.on .txt_area {
        color: #000; }
      .real_teacher .pagination button.slide_16 .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_16_off.png"); }
      .real_teacher .pagination button.slide_16:hover .img_area, .real_teacher .pagination button.slide_16:active .img_area, .real_teacher .pagination button.slide_16.on .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_16_on.png"); }
      .real_teacher .pagination button.slide_16 .txt_area {
        left: 0; }
      .real_teacher .pagination button.slide_21 .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_21_off.png"); }
      .real_teacher .pagination button.slide_21:hover .img_area, .real_teacher .pagination button.slide_21:active .img_area, .real_teacher .pagination button.slide_21.on .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_21_on.png"); }
      .real_teacher .pagination button.slide_21 .txt_area {
        left: 22px; }
      .real_teacher .pagination button.slide_18 .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_18_off.png"); }
      .real_teacher .pagination button.slide_18:hover .img_area, .real_teacher .pagination button.slide_18:active .img_area, .real_teacher .pagination button.slide_18.on .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_18_on.png"); }
      .real_teacher .pagination button.slide_18 .txt_area {
        left: 10px; }
      .real_teacher .pagination button.slide_19 .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_19_off.png"); }
      .real_teacher .pagination button.slide_19:hover .img_area, .real_teacher .pagination button.slide_19:active .img_area, .real_teacher .pagination button.slide_19.on .img_area {
        background-image: url("https://cf.realclass.co.kr/images/bg_main_teacher_19_on.png"); }
      .real_teacher .pagination button.slide_19 .txt_area {
        left: 6px; }
      .real_teacher .pagination button.slide_24 .img_area {
        background-image: url("https://cf.realclass.co.kr/qualson/images/bg_main_teacher_24_off.png");
        background-positon: right top; }
      .real_teacher .pagination button.slide_24:hover .img_area, .real_teacher .pagination button.slide_24:active .img_area, .real_teacher .pagination button.slide_24.on .img_area {
        background-image: url("https://cf.realclass.co.kr/qualson/images/bg_main_teacher_24_on.png");
        background-positon: right top; }
      .real_teacher .pagination button.slide_24 .txt_area {
        left: 0; }
      .real_teacher .pagination button.slide_27 .img_area {
        background-image: url("https://cf.realclass.co.kr/qualson/images/bg_main_teacher_27_off.png");
        background-positon: right top; }
      .real_teacher .pagination button.slide_27:hover .img_area, .real_teacher .pagination button.slide_27:active .img_area, .real_teacher .pagination button.slide_27.on .img_area {
        background-image: url("https://cf.realclass.co.kr/qualson/images/bg_main_teacher_27_on.png");
        background-positon: right top; }
      .real_teacher .pagination button.slide_27 .txt_area {
        left: 0; }
  .real_teacher .controller button {
    width: 530px;
    height: 100%;
    top: 0; }
  .real_teacher .controller .btn_prev {
    left: -320px; }
  .real_teacher .controller .btn_next {
    right: -210px; }
  .real_teacher .contents li {
    height: 421.6px;
    min-height: 421.6px;
    position: relative;
    display: none; }
    .real_teacher .contents li.show {
      display: block; }
    .real_teacher .contents li .img_area img {
      width: 842px;
      height: auto; }
    .real_teacher .contents li .btn_link {
      z-index: 1; }
  .real_teacher .slide_16 .teacher_info .txt_area {
    bottom: 140.8px; }
  .real_teacher .slide_21 .teacher_info .txt_area {
    bottom: 116.8px; }
  .real_teacher .slide_18 .teacher_info .txt_area {
    bottom: 116.8px; }
  .real_teacher .slide_19 .teacher_info .txt_area {
    bottom: 116.8px; }
  .real_teacher .slide_24 .teacher_info .txt_area {
    bottom: 140.8px; }
  .real_teacher .slide_27 .teacher_info .txt_area {
    bottom: 136px; }
  .real_teacher .teacher_info {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right; }
    .real_teacher .teacher_info p {
      padding-right: 6px; }
      .real_teacher .teacher_info p span {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.7); }
    .real_teacher .teacher_info strong {
      margin-bottom: 6px;
      display: inline-block;
      font-size: 20px;
      font-weight: 500; }
      .real_teacher .teacher_info strong span {
        font-size: 14px; }
    .real_teacher .teacher_info .txt_area {
      position: absolute;
      right: 0; }
    .real_teacher .teacher_info .img_area img {
      display: block; }
  .real_teacher .btn_link {
    min-width: 272px;
    position: absolute;
    right: 0;
    bottom: 44px;
    text-align: center; }
  .real_teacher .icon_qualified {
    margin-top: 12px; }
  .real_teacher .review_box {
    width: 440px;
    min-height: 194.4px;
    padding: 34px 29.75px 17px 42.5px;
    position: absolute;
    bottom: -69.6px;
    border: 1px solid #2bde73;
    background-color: rgba(235, 235, 235, 0.9); }
    .real_teacher .review_box:before {
      top: 19.2px;
      left: 27.2px; }
  .real_teacher .txt_group {
    padding: 0 0 18.7px 2.55px;
    margin-bottom: 12px;
    font-size: 14.4px;
    line-height: 1.4;
    border-bottom: 0.5px solid #2bde73; }
    .real_teacher .txt_group strong {
      margin-bottom: 9.6px;
      display: block; }
  .real_teacher .img_group > * {
    display: inline-block;
    vertical-align: middle; }
  .real_teacher .img_group strong {
    margin-left: 9.6px;
    font-size: 11.2px; }
    .real_teacher .img_group strong:after {
      content: '|';
      margin-left: 9.6px;
      display: inline-block; }
  .real_teacher .img_group em {
    margin-left: 9.6px;
    font-size: 12px;
    font-style: normal; }
  .real_teacher .img_round {
    width: 46.4px;
    height: 46.4px; }
    .real_teacher .img_round img {
      width: 100%;
      height: auto; }

.real_training {
  padding: 187px 0 0 0;
  overflow: hidden; }
  .real_training .top_section .inner,
  .real_training .bottom_section .inner {
    padding: 0 93.5px 0 93.5px; }
  .real_training .top_section {
    height: 742px; }
  .real_training .bottom_section {
    min-height: 488px;
    padding-bottom: 75.2px;
    background-color: #3ad478; }
    .real_training .bottom_section .inner {
      padding-top: 180px; }
  .real_training .slope:before {
    height: 648px;
    background-color: #3ad478; }
  .real_training .section_title {
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -153px -1066px;
    width: 159px;
    height: 39px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .real_training .section_title {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .real_training .review_section .txt_group {
    min-height: 151.2px; }
    .real_training .review_section .txt_group strong {
      margin-bottom: 6.4px;
      display: block; }
  .real_training .review_section .img_group em {
    margin-left: 0; }
  .real_training .review_section .icon_qualified {
    margin-left: 11.2px; }
  .real_training h2 {
    margin-top: 24px; }
  .real_training .step1 span,
  .real_training .step2 span {
    display: inline-block; }
  .real_training .step1 .txt_area,
  .real_training .step2 .txt_area {
    font-size: 20.8px; }
  .real_training .step1 .img_area,
  .real_training .step2 .img_area {
    background: url("https://cf.realclass.co.kr/qualson/images/home_training02_shadow.png");
    background-size: 100% auto; }
    .real_training .step1 .img_area img,
    .real_training .step2 .img_area img {
      width: 216px;
      height: auto; }
  .real_training .step1 strong,
  .real_training .step2 strong {
    margin-top: 19.2px;
    display: block;
    font-size: 27.2px;
    font-weight: 500; }
  .real_training .step1 {
    padding-right: 170px;
    text-align: right;
    color: #2bde73;
    position: relative;
    z-index: 1; }
    .real_training .step1 span {
      vertical-align: top; }
    .real_training .step1 .txt_area {
      padding: 58px 15px 0 0; }
  .real_training .step2 {
    padding-left: 200px;
    position: absolute;
    top: -320px;
    left: 0;
    color: #fff; }
    .real_training .step2 span {
      vertical-align: bottom; }
    .real_training .step2 .txt_area {
      padding: 0 0 46px 20px; }

.real_easy {
  padding: 88.4px 0 64.6px 0;
  background-color: #3ad478; }
  .real_easy .inner {
    padding: 0 93.5px 0 93.5px; }
  .real_easy h2 {
    color: #0b6f38; }
    .real_easy h2 strong {
      color: #fff; }
  .real_easy h3 {
    margin-top: 72px;
    font-size: 19.2px; }
  .real_easy .btn_link {
    margin: 0 auto;
    margin-top: 40px; }
  .real_easy ol {
    margin-top: 48px; }
  .real_easy li {
    padding: 0 8.5px 0 8.5px; }
    .real_easy li + li:after {
      content: '';
      position: absolute;
      top: 49.6px;
      left: -8px;
      background-image: url(../images/sprite/common.png);
      background-position: -1433px -1247px;
      width: 16px;
      height: 23px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .real_easy li + li:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .real_easy li span {
      width: 152px;
      height: 145px;
      border: 7px solid #fff; }
      .real_easy li span img {
        bottom: 0; }
    .real_easy li p {
      margin-top: 25.6px;
      color: #0b6f38; }
      .real_easy li p strong {
        margin-bottom: 11.2px;
        display: block;
        font-size: 14.4px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8); }
      .real_easy li p i {
        width: 27.2px;
        height: 27.2px;
        margin: 0 auto;
        margin-top: 11.2px;
        display: block;
        font-style: normal;
        font-size: 11.2px;
        font-weight: 600;
        line-height: 28px;
        border-radius: 50%;
        color: #fff;
        background-color: #11974d; }
  .real_easy .step1 img {
    left: -13px; }
  .real_easy .step2 img {
    left: -12px; }
  .real_easy .step3 img {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
  .real_easy .media_group {
    margin-top: 64px; }

.real_plan h2,
.real_number h2 {
  font-size: 27.2px; }
  .real_plan h2 strong,
  .real_number h2 strong {
    font-size: 57.6px;
    font-weight: 500; }

.real_plan .bottom_section,
.real_number .bottom_section {
  padding: 0 93.5px 0 93.5px;
  clear: both; }
  .real_plan .bottom_section strong,
  .real_number .bottom_section strong {
    width: 472px;
    min-height: 48px;
    display: inline-block;
    font-weight: 400;
    vertical-align: middle; }
  .real_plan .bottom_section a,
  .real_plan .bottom_section button,
  .real_number .bottom_section a,
  .real_number .bottom_section button {
    float: right;
    min-width: 200px; }

.real_plan {
  padding: 96.9px 0 69.7px 0; }
  .real_plan .top_section {
    position: relative; }
    .real_plan .top_section h2 {
      position: absolute;
      top: 52.8px;
      left: 88px; }
    .real_plan .top_section .img_area {
      padding: 0 59.5px 0 59.5px; }
      .real_plan .top_section .img_area img {
        display: block;
        width: 100%;
        height: auto; }
  .real_plan .bottom_section {
    padding-top: 74.4px; }
    .real_plan .bottom_section p {
      padding: 28.9px 49.3px 28.9px 49.3px;
      background-color: #d6dee4; }
      .real_plan .bottom_section p button,
      .real_plan .bottom_section p a {
        margin-top: 16px; }
    .real_plan .bottom_section strong {
      line-height: 1.4; }
      .real_plan .bottom_section strong span {
        display: block;
        margin-bottom: 9.6px;
        font-size: 19.2px; }

.real_number {
  padding: 110.5px 0 76.5px 0;
  background-color: rgba(217, 224, 228, 0.5); }
  .real_number h2 strong {
    margin-top: 16px;
    display: block; }
  .real_number .left_section {
    width: 616px; }
  .real_number .right_section {
    width: 352px;
    padding-top: 88px;
    margin-left: 56px;
    float: right; }
  .real_number .bottom_section {
    padding-top: 88px; }
    .real_number .bottom_section p {
      padding: 51px 59.5px 42.5px 59.5px;
      background-color: #fff; }
    .real_number .bottom_section strong {
      font-size: 19.2px;
      line-height: 1.2; }
    .real_number .bottom_section a {
      color: #fff; }
  .real_number .row {
    font-size: 0; }
    .real_number .row + .row {
      margin-top: 33.6px; }
    .real_number .row strong,
    .real_number .row i {
      color: #2bde73; }
    .real_number .row i,
    .real_number .row span,
    .real_number .row em {
      line-height: 1.6; }
    .real_number .row i,
    .real_number .row em {
      font-style: normal; }
    .real_number .row span {
      font-size: 14.4px; }
    .real_number .row strong {
      width: 264px;
      padding-right: 8px;
      display: inline-block;
      font-size: 76.8px;
      font-weight: 100;
      text-align: right;
      line-height: 1; }
      .real_number .row strong i {
        font-size: 68px;
        display: inline-block; }
    .real_number .row p {
      width: 352px;
      padding-left: 8px;
      display: inline-block; }
    .real_number .row span {
      margin-bottom: 4.8px;
      display: block;
      border-bottom: 1px solid #2bde73; }
    .real_number .row em {
      font-size: 11.2px;
      color: #535a60; }
  .real_number .btn_trial {
    background-color: #000;
    color: #fff; }
    .real_number .btn_trial:before {
      background-image: url(../images/sprite/common.png);
      background-position: -1069px -1190px;
      width: 40px;
      height: 20px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .real_number .btn_trial:before {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }

.real_story h2 {
  text-align: center;
  color: #3ad478;
  font-size: 27.2px; }

.real_story .inner {
  padding: 0 93.5px 0 93.5px; }

.real_story .vision {
  padding: 113.9px 0 70.55px 0;
  text-align: center; }
  .real_story .vision img {
    display: block; }
  .real_story .vision p {
    font-size: 14.4px;
    color: #8c8d8f;
    line-height: 1.5; }
  .real_story .vision .img_group {
    margin-top: 56px; }
    .real_story .vision .img_group img {
      margin: 0 auto; }

.real_story h3 {
  margin-top: 36px;
  color: #00c047;
  font-size: 16px;
  line-height: 1.4; }
  .real_story h3 strong {
    display: block;
    font-size: 20.8px; }
  .real_story h3 span {
    width: 358.4px;
    height: 99.2px;
    margin: 0 auto;
    margin-top: 24px;
    display: block;
    background-color: #1c94c4; }
  .real_story h3 em {
    margin-top: 10px;
    display: block;
    font-size: 11px;
    font-style: normal;
    color: #c1c3c7; }

.real_story .media_group {
  width: 496px;
  padding: 0 25.5px 0 25.5px;
  margin: 0 auto;
  margin-top: 62.4px;
  margin-bottom: 84.8px; }
  .real_story .media_group img {
    width: 100%;
    height: auto; }
  .real_story .media_group .content_box {
    position: relative; }
    .real_story .media_group .content_box .img_area {
      width: 225px;
      height: 225px;
      margin: 0 auto 20px;
      display: block; }
    .real_story .media_group .content_box strong {
      font-size: 18px; }
    .real_story .media_group .content_box + .content_box:before {
      content: '';
      position: absolute;
      top: 100px;
      left: -74px;
      background-image: url(../images/sprite/common.png);
      background-position: -931px -1190px;
      width: 48px;
      height: 28px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .real_story .media_group .content_box + .content_box:before {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
  .real_story .media_group .left_section .img_area {
    color: #5b5b5b; }
  .real_story .media_group .left_section strong {
    color: #000; }
  .real_story .media_group .right_section .img_area {
    color: #2bde73; }
  .real_story .media_group .right_section strong {
    color: #2bde73; }

.real_story .img_group img {
  width: auto;
  height: 170px; }

.real_story .review_section {
  background-color: #f8f8f9; }
  .real_story .review_section .inner {
    padding: 47.6px 187px 42.5px 187px; }
  .real_story .review_section .review_box:before {
    display: none; }
  .real_story .review_section .txt_group strong {
    margin-bottom: 9.6px;
    display: block; }
  .real_story .review_section .img_group em {
    margin-left: 0; }

.real_question {
  padding: 66.3px 0 52.7px 0;
  background-color: #3ad478; }
  .real_question .inner {
    padding: 0 136px 0 136px;
    font-size: 0; }
  .real_question h2 span {
    font-size: 14.4px; }
  .real_question ul {
    width: 49.9%;
    margin-top: 32px;
    display: inline-block;
    font-size: 0;
    text-align: left;
    vertical-align: top; }
  .real_question li {
    margin-bottom: 7.2px;
    padding: 0 17px 0 17px;
    font-size: 13px;
    cursor: pointer; }
    .real_question li:focus {
      outline: none; }
    .real_question li.open .qna_body {
      display: block; }
  .real_question .qna_box {
    background-color: rgba(63, 188, 113, 0.5); }
  .real_question .qna_header {
    padding: 11.05px 10.2px 10.2px 40.8px;
    position: relative;
    background-color: #3fbc71;
    color: #fff;
    line-height: 1.4; }
    .real_question .qna_header:before {
      content: '';
      width: 17.6px;
      height: 3px;
      position: absolute;
      top: 16px;
      left: 12px;
      background-color: #79dba0; }
  .real_question .qna_body {
    display: none;
    padding: 12.75px 27.2px 15.3px 40.8px;
    line-height: 1.4; }
    .real_question .qna_body a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid;
      cursor: pointer; }
    .real_question .qna_body img {
      max-width: 100%;
      height: auto;
      display: block; }

.real_help {
  padding-top: 60.8px; }
  .real_help .inner {
    text-align: center; }
  .real_help h3 {
    margin-bottom: 22.4px;
    text-align: center;
    font-size: 20.8px; }
  .real_help p {
    line-height: 1.4;
    color: #687980; }
  .real_help .bottom_section {
    margin-top: 16px; }
    .real_help .bottom_section img {
      width: 100%;
      height: auto; }
  .real_help button {
    display: inline-block;
    margin-top: 14.4px; }
  .real_help img {
    display: block;
    margin: 0 auto; }

.dot {
  position: relative; }
  .dot:before {
    content: '';
    width: 4px;
    height: 4px;
    margin-left: -2px;
    position: absolute;
    top: -6px;
    left: 50%;
    border-radius: 50%;
    background-color: #2bde73; }

.btn_contact {
  padding: 0 49px;
  border: 2px solid;
  background-color: #fff;
  color: #2bde73;
  line-height: 46px;
  font-size: 18px; }
  .btn_contact:before {
    content: '';
    margin-right: 24px;
    display: inline-block;
    background-image: url(../images/sprite/common.png);
    background-position: -1124px -1190px;
    width: 40px;
    height: 20px;
    vertical-align: middle; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .btn_contact:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.btn_link {
  display: block;
  padding: 0 32px;
  line-height: 50px;
  font-size: 16px; }
  .btn_link:before {
    margin-right: 19px; }

.btn_trial {
  padding: 0 68px;
  border: none;
  line-height: 60px;
  font-size: 18px; }
  .btn_trial:before {
    margin-right: 30px; }

.review_box {
  position: relative; }
  .review_box:before {
    content: '';
    position: absolute;
    background-image: url(../images/sprite/common.png);
    background-position: -368px -1128px;
    width: 56px;
    height: 40px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .review_box:before {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }
  .review_box .img_group > * {
    display: inline-block;
    vertical-align: middle; }

.review_section {
  padding: 0 93.5px 0 93.5px;
  font-size: 0; }
  .review_section .review_box {
    width: 50%;
    padding-left: 12px;
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .review_section .review_box:before {
      top: -21.6px;
      left: 8px; }
  .review_section .txt_group {
    padding-bottom: 20.8px;
    margin-bottom: 12px;
    font-size: 12.8px;
    line-height: 1.4;
    border-bottom: 1px solid rgba(111, 117, 125, 0.5); }
  .review_section .img_group {
    min-height: 40px;
    padding-left: 4px;
    position: relative; }
    .review_section .img_group strong {
      margin-bottom: 2px;
      display: block;
      font-size: 13px; }
    .review_section .img_group em {
      margin-left: 11.2px;
      font-size: 11px;
      font-style: normal; }
  .review_section .img_round {
    width: 40px;
    height: 40px; }

.icon_qualified {
  display: inline-block;
  background-image: url(../images/sprite/common.png);
  background-position: -958px -1066px;
  width: 68px;
  height: 29px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon_qualified {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }
  .icon_qualified:before {
    content: '인증됨'; }

.jw_player {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.slope {
  position: relative; }
  .slope:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 109%;
    height: 101%;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: rotate(-9deg);
    -moz-transform: rotate(-9deg);
    -ms-transform: rotate(-9deg);
    -o-transform: rotate(-9deg);
    transform: rotate(-9deg); }

.popup_contract .pop_inner {
  width: 620px;
  margin: 0 auto;
  position: relative;
  background-color: #fff; }

.popup_contract .pop_body {
  border-radius: 3px;
  box-shadow: 2px 0 7px rgba(0, 0, 0, 0.56);
  overflow: hidden; }
  .popup_contract .pop_body .img_group {
    display: block; }
    .popup_contract .pop_body .img_group img {
      width: 100%;
      height: auto;
      display: block; }

.popup_contract .btn_close_popup {
  position: absolute;
  top: 10px;
  right: -40px;
  background-color: transparent;
  border: none;
  background-image: url(../images/sprite/common.png);
  background-position: -46px -1346px;
  width: 31px;
  height: 31px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .popup_contract .btn_close_popup {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }

.duplicate_error_page .main {
  padding: 70px 0;
  text-align: center; }

.duplicate_error_page h2 {
  padding-top: 108px;
  background: url("https://cf.realclass.co.kr/qualson/images/bg_duplicate_error.png") no-repeat center top;
  background-size: 20px auto;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600; }

.duplicate_error_page p {
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.4; }

.duplicate_error_page button {
  font-size: 15px; }

.duplicate_error_page .btn_bg_white {
  color: #000;
  border: 2px solid; }

.duplicate_error_page .btn_bg_black {
  color: #2bde73; }

.duplicate_error_page .btn_group {
  margin-top: 45px; }
  .duplicate_error_page .btn_group button + button {
    margin-top: 12px; }

@media (min-width: 720px) {
  .duplicate_error_page .main {
    padding: 105px 0; }
  .duplicate_error_page h2 {
    padding-top: 200px;
    background-image: url("https://cf.realclass.co.kr/qualson/images/bg_duplicate_error_pc.png");
    background-size: 143px auto;
    font-size: 22px; }
  .duplicate_error_page p {
    font-size: 16px; }
  .duplicate_error_page button {
    height: 55px;
    font-size: 16px;
    line-height: 53px; }
  .duplicate_error_page .btn_group {
    margin-top: 30px; }
    .duplicate_error_page .btn_group button + button {
      margin-top: 0;
      margin-left: 8px; } }

.error_page .header {
  color: rgba(255, 255, 255, 0.8); }
  .error_page .header h1 a {
    background-image: url(../images/sprite/common.png);
    background-position: -658px -729px;
    width: 134px;
    height: 23px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .error_page .header h1 a {
        background-image: url(../images/sprite/common@2x.png);
        background-size: 1459px 1423px; } }

.error_page .main {
  padding-top: 80px;
  text-align: center; }
  .error_page .main img {
    display: block;
    margin: 0 auto 40px; }
  .error_page .main h2 {
    margin-top: 136px;
    font-size: 22px; }
  .error_page .main button {
    margin-top: 48px;
    color: #2bde73; }

.jw_full .header,
.jw_full .floating_banner {
  z-index: -1; }

.jw_full .real_video .pagination,
.jw_full .real_video .controller button,
.jw_full .real_video .contract_info_group {
  z-index: -1; }

.jw_full .full_banner .pagination_group,
.jw_full .chrome_download_banner {
  z-index: -1; }

.faq_page .main {
  min-height: 100%;
  padding-top: 70px; }
  .faq_page .main .inner {
    max-width: 710px; }

.faq_page .article {
  padding: 34px 0 283px; }
  .faq_page .article h2 {
    font-size: 20px; }

.faq_page .faq_title {
  margin-bottom: 20px;
  text-align: center; }
  .faq_page .faq_title h2 {
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: bold; }
  .faq_page .faq_title p {
    font-size: 14px; }
  .faq_page .faq_title .channel_btn {
    width: 240px;
    height: 50px;
    margin: 20px 0 30px;
    color: #fff;
    font-size: 16px;
    border: 0;
    background: #000; }

.faq_page .local_nav ul {
  margin: 0 -10px;
  font-size: 0; }

.faq_page .local_nav li {
  width: 49.9%;
  padding: 7px 10px;
  display: inline-block; }
  .faq_page .local_nav li.on a {
    background-color: #2bde73;
    color: #fff; }
  .faq_page .local_nav li a {
    display: block;
    font-size: 16px;
    color: #2bde73;
    text-decoration: none;
    border: 1px solid #2bde73;
    text-align: center;
    line-height: 56px;
    cursor: pointer; }

.faq_page .content {
  margin-top: 34px; }
  .faq_page .content ul {
    margin-top: 10px;
    font-size: 16px; }
  .faq_page .content li {
    border-bottom: 1px solid #e1e1e1; }
  .faq_page .content .open .list_body {
    display: block; }
  .faq_page .content .open .list_head:after {
    display: none; }
  .faq_page .content .tag {
    min-width: 80px;
    max-width: 86px;
    padding: 3px 2px 2px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 12px;
    white-space: nowrap;
    color: #fff;
    background-color: #2bde73;
    border-radius: 30px;
    text-align: center;
    font-style: normal;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  .faq_page .content .list_head {
    position: relative;
    padding: 17px 60px 17px 90px;
    cursor: pointer; }
    .faq_page .content .list_head:after {
      content: '';
      position: absolute;
      background-image: url(../images/sprite/common.png);
      background-position: -996px -1274px;
      width: 16px;
      height: 10px;
      right: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .faq_page .content .list_head:after {
          background-image: url(../images/sprite/common@2x.png);
          background-size: 1459px 1423px; } }
    .faq_page .content .list_head * {
      cursor: pointer; }
    .faq_page .content .list_head:focus {
      outline: none; }
    .faq_page .content .list_head span {
      display: inline-block; }
  .faq_page .content .list_body {
    padding: 10px 0 20px;
    display: none;
    line-height: 1.5;
    color: #4d4d4d; }
    .faq_page .content .list_body a {
      display: inline-block;
      color: inherit; }
    .faq_page .content .list_body img {
      max-width: 100%;
      height: auto;
      display: block; }

.faq_page .footer {
  margin-top: -210px; }

.popup_coupon .popup_coupon_inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 447px;
  height: 570px;
  background-image: url("https://cf.realclass.co.kr/qualson/images/popup-bg@2x.png");
  background-size: cover;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%); }

.popup_coupon .coupon_header {
  width: 317px;
  height: 96px;
  margin: 48px auto 26px; }

.popup_coupon .coupon_content {
  width: 324px;
  height: 209px;
  border-radius: 10px;
  box-shadow: 1.6px 2.5px 0 0 rgba(12, 11, 10, 0.15);
  border: solid 2px #ebebeb;
  background-color: #ffffff;
  margin: 0 auto;
  margin-left: 65px; }
  .popup_coupon .coupon_content .top {
    background: #f2f3f5;
    height: 56.6px;
    display: flex;
    align-items: center; }
    .popup_coupon .coupon_content .top .title {
      height: 21px;
      margin: 0 auto; }
    .popup_coupon .coupon_content .top .title-1 {
      width: 169px; }
    .popup_coupon .coupon_content .top .title-2 {
      width: 224px; }
    .popup_coupon .coupon_content .top .title-3 {
      width: 205px; }
  .popup_coupon .coupon_content .bottom {
    margin-top: 24px; }
    .popup_coupon .coupon_content .bottom .percent {
      height: 100px;
      margin: 0 auto; }
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_5,
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_7 {
      width: 127px; }
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_10,
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_15 {
      width: 147px; }
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_35 {
      width: 137px; }
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_40,
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_45 {
      width: 138px; }
    .popup_coupon .coupon_content .bottom .percent-DISCOUNT_100000WON {
      width: 193px; }

.popup_coupon .time {
  width: 196px;
  height: 29px;
  background: #ff414d;
  margin: 18px auto 38px; }
  .popup_coupon .time span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 17.5px;
    color: #fff; }

.popup_coupon .btn-coupon {
  width: 356px;
  height: 57px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin: 0 auto; }

.popup_coupon .guide-text {
  font-size: 15.5px;
  color: #000;
  display: flex;
  justify-content: center;
  margin-top: 14px; }

.popup_coupon .btn_close_popup {
  position: absolute;
  background-image: url(../images/sprite/common.png);
  background-position: -46px -1346px;
  width: 31px;
  height: 31px;
  top: 0;
  right: -10px;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  background-color: transparent; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .popup_coupon .btn_close_popup {
      background-image: url(../images/sprite/common@2x.png);
      background-size: 1459px 1423px; } }
